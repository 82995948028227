import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-places-visited-commentary',
  templateUrl: './admin-places-visited-commentary.component.html',
  styleUrls: ['./admin-places-visited-commentary.component.css']
})
export class AdminPlacesVisitedCommentaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
