import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-admin-setting-update',
  templateUrl: './admin-setting-update.component.html',
  styleUrls: ['./admin-setting-update.component.css']
})
export class AdminSettingUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  constructor() { }

  ngOnInit(): void {
  }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
