import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as Rx from 'rxjs/Rx';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-admin-hospedaje-add-hospedaje',
  templateUrl: './admin-hospedaje-add-hospedaje.component.html',
  styleUrls: ['./admin-hospedaje-add-hospedaje.component.css']
})
export class AdminHospedajeAddHospedajeComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  TypeAccommodation = {
    main: {
      uuid: '',
      // Categoria del hotel, 2 estrellas, 3 estrellas, 4 estrellas, etc.
      hotelCategory: '',
      // Tipo de habitación: doble, sencilla, triple
      typeRoom: '',
      // Precio de la estancia por día
      price: 0.0,
      // Precio en dolares de la estancia por día
      priceEn: 0.0
    },
    system: {
      // Tempora en la que se ubica en ese precio
      dateTypeAccommodation: {
        main: {
          uuid: ''
        }
      },
      // Empresa a la que pertene tipo de hospedaje
      company: {
        main: {
          uuid: ''
        }
      },
      state: {
        main: {
          uuid: ''
        }
      }
    }
  }

  objectType = JSON.parse(JSON.stringify(this.TypeAccommodation));

  DateTypeAccommodation = {
    main: {
      uuid: '',
      // Tipo de temporada, baja false, alta true
      seasonType: false,
      // Fecha de inicio del periodo
      startPeriod: 'dd/MM/YYYY',
      // Fecha final del periodo
      endPeriod: 'dd/MM/YYYY',
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      state: {
        main: {
          uuid: ''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.DateTypeAccommodation));


  typeMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }

  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    }
  }

  paginator = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };

  typeList = [];
  typeAccList = [];


  hotelCategoryList = [{ name: '2 Estrellas' }, { name: '3 Estrellas' }, { name: '4 Estrellas' }, { name: '5 Estrellas' }]
  typeRoomList = [{ name: 'Sencilla' }, { name: 'Doble' }, { name: 'Triple' }, { name: 'Cuádruple' }]
  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(private session: SessionService, private router: Router, public dialog: MatDialog, public activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, public loading: LoadingService, public fb: FormBuilder) {
    this.objectType.system.state.main.uuid = this.session.getState().main.uuid;
    this.objectType.system.company.main.uuid = this.session.getSessionObject().main.uuid;

    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.typeList = [];
          this.typeMin.filter.name = '';
        } else {
          this.typeList = [];
          this.typeMin.filter.name = '';
          this.typeMin.filter.value = '';
        }
        this.getTypeList();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
    this.complexForm = fb.group({
      'price': [null, Validators.compose([Validators.required])],
      'priceEn': [null, Validators.compose([Validators.required])],
      'hotelCategory': [null, Validators.compose([Validators.required])],
      'typeRoom': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.object.main.uuid = params['uuid'];
      this.loading.show(true, "Espere un momento...")
      this.session.getRequest("dateTypeAccommodation:get", this.object).subscribe((data: any) => {
        this.object = data.object;
        this.typeMin.system.company.main.uuid = this.object.system.company.main.uuid;
        this.typeAccList = [];
        this.getTypeList();
        this.loading.hide();
      }, error => {
        console.log('dateTypeAccommodation:get', error);
        this.loading.hide();
      })
    }, error => {
      console.log("activatedRoute:error", error);
    })
  }

  /**función para guardar un tipo de hospedaje
    */
  send() {
    this.objectType.main.uuid = '';
    this.objectType.system.dateTypeAccommodation.main.uuid = this.object.main.uuid;
    this.loading.show(true, "Espere un momento...");
    this.session.getRequest("typeAccommodation:update", this.objectType).subscribe(async (data: any) => {
      this.objectType.main.uuid = data.object.main.uuid;
      this.typeAccList = [];
      this.getTypeList();
      this.complexForm.reset();
      this.loading.hide();
    }, error => {
      console.log('typeAccommodation:update', error);
      this.loading.hide();
    })
  }

  /* funcion para obtener el listado de tipo de hospedaje
    */
  getTypeList() {
    this.typeList = [];
    this.loading.wait();
    this.session.getRequest("typeAccommodation:findAllByCompany", this.typeMin).subscribe(
      (data: any) => {
        this.typeList = data.object.instanceList;
        for (let i = 0; i < this.typeList.length; i++) {
          if (this.typeList[i].system.dateTypeAccommodation.main.uuid == this.object.main.uuid) {
            this.typeAccList.push(this.typeList[i].main);
          }
        }
        this.paginator.total = data.object.total;
        this.loading.hide();
      },
      error => {
        console.log("typeAccommodation:findAllByCompany", error);
        this.loading.hide();
      }
    );
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.typeMin.offset = (event.pageIndex * event.pageSize);
    this.typeMin.max = event.pageSize;
    this.getTypeList();
  }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
