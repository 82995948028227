import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import {MatDialog} from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-periodo-update',
  templateUrl: './admin-periodo-update.component.html',
  styleUrls: ['./admin-periodo-update.component.css']
})
export class AdminPeriodoUpdateComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  minDate = new Date();
    /** Se definen los periodos que corresponden a la temporada alta y temporada baja */
DateTypeAccommodation = {
	main: {
    uuid: '',
// Tipo de temporada, baja false, alta true
    seasonType: false, 
// Fecha de inicio del periodo
    startPeriod: 'dd/MM/YYYY',
// Fecha final del periodo
    endPeriod: 'dd/MM/YYYY',
},
	system: {
    company: {
	    main: {
		    uuid: ''
	    }
    },
    state:{
      main: {
        uuid:''
      }
    }
  }
}

metadata = {
  startPeriod:'dd/MM/yyyy hh:mm:ss',
  endPeriod:'dd/MM/yyyy hh:mm:ss',
}

object = JSON.parse(JSON.stringify(this.DateTypeAccommodation));

constructor(private session: SessionService, private router:Router, public dialog: MatDialog, public activatedRoute:ActivatedRoute, public snackBar: MatSnackBar, public loading:LoadingService, public fb: FormBuilder ) {
  this.object.system.state.main.uuid = this.session.getState().main.uuid;
  this.object.system.company.main.uuid =  this.session.getSessionObject().main.uuid;
  // this.stateList = this.session.getStateList();
  this.complexForm = fb.group({
    'startPeriod': [null, Validators.compose([Validators.required])],
    'endPeriod': [null, Validators.compose([Validators.required])],      
  });
 }

 ngOnInit(): void {
  this.activatedRoute.params.subscribe((params: Params)=>{
    if(params['uuid'] == 'new'){
    }else{
      this.object.main.uuid = params['uuid'];
      this.loading.show(true,"Espere un momento...")
      this.session.getRequest("dateTypeAccommodation:get",this.object).subscribe((data:any)=>{
        this.object = JSON.parse(JSON.stringify(data.object));
       this.metadata.startPeriod = moment(this.object.main.startPeriod).toISOString();
       this.metadata.endPeriod = moment(this.object.main.endPeriod).toISOString();
        this.object = data.object;
        this.loading.hide();
      },error=>{
        console.log('dateTypeAccommodation:get',error);
        this.loading.hide();
      })
    }
  },error=>{
    console.log("activatedRoute:error",error);
  })
}

   /**función para guardar un periodo
  */
    send(){
      this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.loading.show(true,"Espere un momento...");
      this.object.main.startPeriod = moment(this.metadata.startPeriod).format('DD/MM/YYYY hh:mm:ss');
      this.object.main.endPeriod = moment(this.metadata.endPeriod).format('DD/MM/YYYY hh:mm:ss');
      this.session.getRequest("dateTypeAccommodation:update",this.object).subscribe(async(data:any)=>{
        this.object.main.uuid = data.object.main.uuid;
        this.loading.hide();
        this.router.navigate(['/admin/hospedaje'])
      },error=>{
        console.log('dateTypeAccommodation:update',error);
        this.loading.hide();
      })
    }

  
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
