import { Component, OnInit, Inject } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from 'src/app/directive/loading/loading.service';
import { SessionService } from '../../../service/session/session.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as Rx from 'rxjs/Rx';
@Component({
  selector: 'app-admin-copy-tours',
  templateUrl: './admin-copy-tours.component.html',
  styleUrls: ['./admin-copy-tours.component.css']
})
export class AdminCopyToursComponent implements OnInit {
  tourModel = {
    main: {
      uuid: '',
      name: '',
      children: false,
      minimumPerson: 1,
      secludedPerson: 0.0,
      pricePerson: 0.0,
      pricePerChild: 0.0
    },
    complement: {
      description: ''
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      tourSchedules: [],
      state: {
        main: {
          uuid: ''
        }
      },

    }
  };

  placeDepartureModel = {
    main: {
      uuid: '',
      address: '',
      hour: 0,
      minute: 0
    },
    system: {
      tourSchedule: {
        main: {
          uuid: ''
        }
      }
    }
  }

  tourScheduleModel = {
    main: {
      uuid: '',
      day: ''
    },
    system: {
      tour: {
        main: {
          uuid: ''
        }
      },
      placeDepartures: []
    }
  };

  daysList = [{
    name: 'Domingo',
    value: 0,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Lunes',
    value: 1,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Martes',
    value: 2,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Miercoles',
    value: 3,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Jueves',
    value: 4,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Viernes',
    value: 5,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Sabado',
    value: 6,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }];
  tourMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  tourList = [];
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    },
    tourScheduleSelectedToCopy: JSON.parse(JSON.stringify(this.tourScheduleModel))
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  daysAvaiblable = [];
  object = JSON.parse(JSON.stringify(this.tourModel));

  constructor(public loadding: LoadingService, protected session: SessionService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminCopyToursComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.object = JSON.parse(JSON.stringify(this.data));
    for (let item of this.object.system.tourSchedules) {
      if(item.system.placeDepartures.length > 0){
        this.daysAvaiblable.push(item);
      }
      for(let i = 0; i < this.daysList.length; i++){
        if(this.daysList[i].name == item.main.day){
          this.daysList[i].tourSchedule = JSON.parse(JSON.stringify(item));
        }
      }
    }

    for(let item of this.daysAvaiblable){

      for(let i = 0; i < this.daysList.length; i++){
        if(this.daysList[i].name == item.main.day){
          this.daysList[i].tourSchedule = JSON.parse(JSON.stringify(item));
        }
      }

    }

  }

  /**fucnión para guardar el dia seleccionado a copiar.
  */
  daySelectedToCopy() {
    for (let item of this.daysAvaiblable) {
      if (this.metadata.tourScheduleSelectedToCopy.main.uuid == item.main.uuid) {
        this.metadata.tourScheduleSelectedToCopy = JSON.parse(JSON.stringify(item))
      }
    }
  }


  startCopyScheduls() {
    // console.log("Dia seleccionado a copiar", this.metadata.tourScheduleSelectedToCopy);
    // console.log("Dia seleccionado de destino", this.daysList);

    this.loadding.show(true, "Espere un momento...");
    this.object.system.tourSchedules = [];
    for (let dayItem of this.daysList) {
      if (dayItem.selected) {
        //aqui comenzamos a copiar
        let tourScheduleAux = JSON.parse(JSON.stringify(this.tourScheduleModel));
        tourScheduleAux.main.day = dayItem.name;
        tourScheduleAux.system.tour.main.uuid = this.object.main.uuid;
        tourScheduleAux.system.placeDepartures = dayItem.tourSchedule.system.placeDepartures;
        tourScheduleAux.main.uuid = dayItem.tourSchedule.main.uuid;
        for (let departure of this.metadata.tourScheduleSelectedToCopy.system.placeDepartures) {
          let placeDepartureAux = JSON.parse(JSON.stringify(this.placeDepartureModel));
          placeDepartureAux.main.address = departure.main.address;
          placeDepartureAux.main.hour = departure.main.hour;
          placeDepartureAux.main.minute = departure.main.minute;
          placeDepartureAux.system.tourSchedule.main.uuid = tourScheduleAux.main.uuid;
          tourScheduleAux.system.placeDepartures.push(placeDepartureAux);
        }
        this.object.system.tourSchedules.push(tourScheduleAux);
      }
    }
    //actualizamos
    this.session.getRequest("tour:update", this.object).subscribe((data: any) => {
      // this.object = data.object;
      this.session.getRequest("tour:get", {main:{uuid:this.object.main.uuid}}).subscribe((data: any) => {
        this.loadding.hide();
        this.object = data.object;
        this.close(true);
      }, error => {
        console.log('tour:get', error);
        this.loadding.hide();
      })
    }, error => {
      console.log('tour:update', error);
      this.loadding.hide();
    })
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'benefaction:001';
    }
    this.dialogRef.close(object);
  }
}
