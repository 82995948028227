<div class="row justify-content-center mt-2">
  <div class="col-12">
    <div class="upload-box" *ngIf="editable">
      <div class="upload-box-content">
        <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
          <p>
            Arrastre los archivos aquí o
            <label class="upload-button">
              <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple> navegue
            </label>
            para cargarlos.
          </p>
        </div>
      </div>
    </div>
    <div class="file-box">
      <div class="file-box-content">
        <div class="filename" style="padding-left: 15px; padding-top: 5px; ">
          Listado de archivos cargados.
        </div>
        <br>
        <div class="upload-item" *ngFor="let object of files; let i = index;">
          <div class="upload-item-content">
            <div class="filename">
              <div class="filename-left">
                <span class="material-icons" *ngIf="object.type != 'image/png' && object.type != 'image/jpeg' && object.type != 'image/jpg'">file_copy</span>
                <div class="minAvatar">
                  <img src="https://turismo.keysoft.mx/assets/event/{{getImageText(object.name)}}" *ngIf="object.uploaded && (object.type == 'image/png' || object.type == 'image/jpeg' || object.type == 'image/jpg')" alt="{{object.name}}">
                </div>
                <span>{{ object.name }}</span>
              </div>
              <div class="filename-right">
                <button mat-icon-button color="accent" (click)="downloadAFile(object)" matTooltip="Click para descargar el archivo" *ngIf="object.uuidFile != ''">
                  <span class="material-icons">cloud_download</span>
                </button>
                <button mat-icon-button color="warn" (click)="deleteAFile(object)" matTooltip="Click para eliminar el archivo">
                  <span class="material-icons">delete_forever</span>
                </button>
                <button mat-icon-button color="accent"  *ngIf="!object.upload && !object.uploaded " (click)="uploadFile(object)" matTooltip="Click para subir el archivo">
                  <span class="material-icons">cloud_upload</span>
                </button>
                <mat-icon aria-hidden="false" color="accent" *ngIf="object.uploaded && object.valueProcess >= 100" style="padding-top: 10px;">cloud_done</mat-icon>
              </div>
            </div>
            <div class="">
              <mat-progress-bar [mode]="object.mode" [value]="object.valueProcess"></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
