<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Listado de Usuarios</h3>
          </div>
          <div class="col-3">
              <button type="button" class="btn btn-danger btn-block" routerLink="/admin/setting/update">Agregar Usuario</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-center">
          <mat-card-content>
            <div class="row">
              <div class="col-12">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">NOMBRE</th>
                      <th scope="col">CORREO</th>
                      <th scope="col">ESTADOS</th>
                      <th scope="col">ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <th scope="row">
                        Roxana Vasquez
                      </th>
                      <th>
                        roxana.vasquez@sti.mx
                      </th>
                      <th>
                        Oaxaca, Puebla, Veracruz
                      </th>
                      <td>
                        <button class="mr-2" color="primary" mat-icon-button matTooltip="Editar" routerLink="/admin/setting/update">
                          <i class="material-icons">mode_edit</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
