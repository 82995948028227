import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-admin-hospedaje',
  templateUrl: './admin-hospedaje.component.html',
  styleUrls: ['./admin-hospedaje.component.css']
})
export class AdminHospedajeComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  dateTypeMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }

  dateTypeList = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(public loadding:LoadingService, protected session: SessionService, public snackBar: MatSnackBar) {
    this.dateTypeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.dateTypeList = [];
        this.dateTypeMin.filter.name = '';
      }else{
        this.dateTypeList = [];
        this.dateTypeMin.filter.name = '';
        this.dateTypeMin.filter.value = '';
      }
      this.getDateTypeList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit(): void {
    this.getDateTypeList();
  }

  /**funcionalidad para obetner el listado de periodos
  **/
   getDateTypeList(){
    this.dateTypeList = [];
    this.loadding.wait();
    this.session.getRequest("dateTypeAccommodation:findAllByCompany",this.dateTypeMin).subscribe(
      (data:any) => {
        this.dateTypeList = data.object.instanceList;
        this.paginator.total = data.object.total;
        this.loadding.hide();
     },
     error => {
       console.log("dateTypeAccommodation:findAllByCompany",error);
       this.loadding.hide();
     }
    );
  }

   // funcion para obtener los datos del paginado.
   onPaginateChange(event){
    this.dateTypeMin.offset = (event.pageIndex * event.pageSize);
    this.dateTypeMin.max = event.pageSize;
    this.getDateTypeList();
  }
 /* funcionalidad para cambiar el menú
  */
 toogleMenu(ev){
  this.drawerCtrl.opened = ev;
}
}
