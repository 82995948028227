import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxUploaderModule } from 'ngx-uploader';



import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { KeysoftUploadComponent } from './directive/keysoft-upload/keysoft-upload';
import { KeysoftUploadPackageComponent } from './directive/keysoft-upload-package/keysoft-upload';
import { KeysoftUploadEventsComponent } from './directive/keysoft-upload-events/keysoft-upload'


import { MenuService } from './admin/admin-menu/menu.service';

import { LoginComponent } from './login/login.component';
import { SelectedObjectComponent } from './login/selected-object/selected-object.component';
import { AdminComponent } from './admin/admin.component';
import { AdminMenuComponent } from './admin/admin-menu/admin-menu.component';
import { ToolBarComponent } from './admin/tool-bar/tool-bar.component';
import { AdminToursComponent } from './admin/admin-tours/admin-tours.component';
import { AdminToursUpdateComponent } from './admin/admin-tours/admin-tours-update/admin-tours-update.component';
import { AdminPackageComponent } from './admin/admin-package/admin-package.component';
import { AdminPackageUpdateComponent } from './admin/admin-package/admin-package-update/admin-package-update.component';
import { AdminPlacesVisitedComponent } from './admin/admin-places-visited/admin-places-visited.component';
import { AdminNewPlacesComponent } from './admin/admin-new-places/admin-new-places.component';
import { AdminNewPlacesUpdateComponent } from './admin/admin-new-places/admin-new-places-update/admin-new-places-update.component';
import { AdminEventsComponent } from './admin/admin-events/admin-events.component';
import { AdminEventsUpdateComponent } from './admin/admin-events/admin-events-update/admin-events-update.component';
import { AdminSettingComponent } from './admin/admin-setting/admin-setting.component';
import { AdminPlacesVisitedCommentaryComponent } from './admin/admin-places-visited/admin-places-visited-commentary/admin-places-visited-commentary.component';
import { AdminSettingUpdateComponent } from './admin/admin-setting/admin-setting-update/admin-setting-update.component';
import { AdminToursAddImageComponent } from './admin/admin-tours/admin-tours-add-image/admin-tours-add-image.component';
import { AdminEventsAddImageComponent } from './admin/admin-events/admin-events-add-image/admin-events-add-image.component';
import { AdminPackageAddImageComponent } from './admin/admin-package/admin-package-add-image/admin-package-add-image.component';
import { from } from 'rxjs';
import { AdminCopyToursComponent } from './admin/admin-tours/admin-copy-tours/admin-copy-tours.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { AdminSaleComponent } from './admin/admin-sale/admin-sale.component';
import { AdminSaleUpdateComponent } from './admin/admin-sale/admin-sale-update/admin-sale-update.component';
import { AdminPackageAddItinerarioComponent } from './admin/admin-package/admin-package-add-itinerario/admin-package-add-itinerario.component';
import { AdminHospedajeComponent } from './admin/admin-hospedaje/admin-hospedaje.component';
import { AdminPeriodoUpdateComponent } from './admin/admin-hospedaje/admin-periodo-update/admin-periodo-update.component';
import { AdminHospedajeAddHospedajeComponent } from './admin/admin-hospedaje/admin-hospedaje-add-hospedaje/admin-hospedaje-add-hospedaje.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    AdminMenuComponent,
    ToolBarComponent,
    AdminToursComponent,
    AdminToursUpdateComponent,
    AdminPackageComponent,
    AdminPackageUpdateComponent,
    AdminPlacesVisitedComponent,
    AdminNewPlacesComponent,
    AdminNewPlacesUpdateComponent,
    AdminEventsComponent,
    AdminEventsUpdateComponent,
    AdminSettingComponent,
    AdminPlacesVisitedCommentaryComponent,
    AdminSettingUpdateComponent,
    SelectedObjectComponent,
    KeysoftUploadComponent,
    KeysoftUploadPackageComponent,
    KeysoftUploadEventsComponent,
    AdminToursAddImageComponent,
    AdminPackageAddImageComponent,
    AdminCopyToursComponent,
    AdminProfileComponent,
    AdminSaleComponent,
    AdminSaleUpdateComponent,
    AdminEventsAddImageComponent,
    AdminPackageAddItinerarioComponent,
    AdminHospedajeComponent,
    AdminPeriodoUpdateComponent,
    AdminHospedajeAddHospedajeComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    SessionModule.forRoot(),
    LoadingModule.forRoot()
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    MenuService
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    SelectedObjectComponent,
    AdminPlacesVisitedCommentaryComponent,
    KeysoftUploadComponent,
    KeysoftUploadPackageComponent,
    KeysoftUploadEventsComponent
  ]
})
export class AppModule { }
