import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-events',
  templateUrl: './admin-events.component.html',
  styleUrls: ['./admin-events.component.css']
})
export class AdminEventsComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  eventMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max:10,
    offset: 0,
    order: '',
    sort:'',
    filter:{
      name:'',
      value: ''
    }
  }
  eventList = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  metadataa = {
    apartDate: '',
    uploadted:false
  }
  pageEvent: PageEvent;


  ContactEventMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    }
  }
contactos = [];
  constructor(public loadding:LoadingService, protected session: SessionService, public snackBar: MatSnackBar) {
    this.eventMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.ContactEventMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.eventList = [];
        this.eventMin.filter.name = '';
      }else{
        this.eventList = [];
        this.eventMin.filter.name = '';
        this.eventMin.filter.value = '';
      }
      this.getEventList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
   }

  ngOnInit(): void {
    this.getEventList();
    this.getContactEventList();
  }
  /**funcionalidad para obetner el listado de eventos
  **/
   getEventList(){
    this.eventList = [];
    this.loadding.wait();
    this.session.getRequestAnonymous("event:findAllByCompany",this.eventMin).subscribe(
      (data:any) => {
        this.eventList = data.object.instanceList;
        this.paginator.total = data.object.total;
        this.loadding.hide();
     },
     error => {
       console.log("event:findAllByCompany",error);
       this.loadding.hide();
     }
    );
  }

  delete(object) {
    // Objecto a Eliminar
    this.loadding.show(true,"Espera un momento...");
    this.session.getRequest("event:delete", object).subscribe((data:any) => {
        for (let x=0; x<this.eventList.length; x++) {
          if (object.main.uuid == this.eventList[x].main.uuid) {
            this.eventList.splice(x, 1);
          }
        }
        this.snackBar.open('Se elimino correctamente el evento seleccionado', 'Información', {duration: 5000});
        this.loadding.hide();
     }, error => {
       console.log("event:delete",error);
       this.loadding.hide();
     });
  }

  getContactEventList(){
    this.contactos = [];
    this.loadding.wait();
    this.session.getRequest("contactEvent:findAllByCompany",this.ContactEventMin).subscribe(
      (data:any) => {
        this.contactos = data.object.instanceList;
        this.paginator.total = data.object.total;
        this.loadding.hide();
     },
     error => {
       console.log("contactEvent:findAllByEvent",error);
       this.loadding.hide();
     }
    );
  }
   // funcion para obtener los datos del paginado.
   onPaginateChange(event){
    this.eventMin.offset = (event.pageIndex * event.pageSize);
    this.eventMin.max = event.pageSize;
    this.getEventList();
  }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
