<mat-drawer-container class="drawerConteiner">
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Agregar tipo de hospedaje </h3>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-danger btn-block" routerLink="/admin/hospedaje">Regresar</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-left">
          <div class="row">
            <div class="col-6">
              <p>Temporada: <strong>{{object.main.seasonType ? 'ALTA':'BAJA'}}</strong> </p>
              <p>Fecha: <strong>del {{object.main.startPeriod | date: 'dd/MM/yyyy'}} al {{object.main.endPeriod | date:
                  'dd/MM/yyyy'}}</strong> </p>
            </div>
          </div>
        </mat-card>
      </div>
      <hr class="col-12">

      <div class="col-10">
        <mat-card class="text-center">
          <div class="row mt-4 mb-3">
            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Tipo de Hospedaje</p>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>Categoria del hotel</mat-label>
                  <mat-select placeholder="Categoria del hotel" [formControl]="complexForm.controls['hotelCategory']"
                    [(ngModel)]="objectType.main.hotelCategory" name="hotelCategory" class="full-width-select">
                    <mat-option [value]="''" *ngIf="hotelCategoryList.length > 0">
                      -- Seleccione un elemento --
                    </mat-option>
                    <mat-option *ngFor="let object of hotelCategoryList" [value]="object.name">
                      {{ object.name }}
                    </mat-option>
                    <mat-option [value]="''" *ngIf="hotelCategoryList.length == 0">
                      -- No hay elementos --
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="complexForm.controls['hotelCategory'].hasError('required')">Campo requerido
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>Tipo de habitación</mat-label>
                  <mat-select placeholder="Tipo de habitación" [formControl]="complexForm.controls['typeRoom']"
                    [(ngModel)]="objectType.main.typeRoom" name="typeRoom" class="full-width-select">
                    <mat-option [value]="''" *ngIf="typeRoomList.length > 0">
                      -- Seleccione un elemento --
                    </mat-option>
                    <mat-option *ngFor="let object of typeRoomList" [value]="object.name">
                      {{ object.name }}
                    </mat-option>
                    <mat-option [value]="''" *ngIf="typeRoomList.length == 0">
                      -- No hay elementos --
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="complexForm.controls['typeRoom'].hasError('required')">Campo requerido
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Precio por día (MXN)</mat-label>
                  <input matInput placeholder="Precio por día" name="price" type="number"
                    [formControl]="complexForm.controls['price']" [(ngModel)]="objectType.main.price">
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="complexForm.controls['price'].hasError('required')">Campo requerido
                  </mat-error>
                  <mat-hint align="start">Ingrese el precio en MXN</mat-hint>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Precio por día (USD)</mat-label>
                  <input matInput placeholder="Precio por día" name="priceEn" type="number"
                    [formControl]="complexForm.controls['priceEn']" [(ngModel)]="objectType.main.priceEn">
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="complexForm.controls['priceEn'].hasError('required')">Campo requerido
                  </mat-error>
                  <mat-hint align="start">Ingrese el precio en USD</mat-hint>
                </mat-form-field>

                <div class="row justify-content-end mt-4">
                  <div class="col-4">
                    <button type="button" class="btn btn-danger btn-block" (click)="send()">Guardar</button>
                  </div>
                </div><br>
              </div>

            </div>
            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Actividades</p>

                <div class="col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Categoria</th>
                        <th scope="col">Habitación</th>
                        <th scope="col">Precio(MXN)</th>
                        <th scope="col">Precio(USD)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let object of typeAccList">
                        <td>{{object.hotelCategory}}</td>
                        <td>{{object.typeRoom}}</td>
                        <td>{{object.price}}</td>
                        <td>{{object.priceEn}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
                  <div class="col-6 ">
                    <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions
                      (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
                    </mat-paginator>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>