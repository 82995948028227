import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-admin-events-add-image',
  templateUrl: './admin-events-add-image.component.html',
  styleUrls: ['./admin-events-add-image.component.css']
})
export class AdminEventsAddImageComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  EventModel = {
    main: {
      uuid: '',
      name: '',
      description: '',
      apartDate:'',
      contact: '',
      contacNumber: '',
      contactEmail: '',
      numberPeople: 0,
      amount: 0.0
    },
    system: {
      state: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      }
    }
  };
  object = JSON.parse(JSON.stringify(this.EventModel));
  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public loading:LoadingService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params)=>{
      this.object.main.uuid = params['uuid'];
      this.loading.show(true,"Espere un momento...")
      this.session.getRequest("event:get",this.object).subscribe((data:any)=>{
        this.object = data.object;
        this.loading.hide();
      },error=>{
        console.log('event:get',error);
        this.loading.hide();
      })
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }


   /* funcionalidad para cambiar el menú
  */
   toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
