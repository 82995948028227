

<div mat-dialog-content class="row justify-content-center mt-2">
  <h2 class="example-h2">Copiar en:</h2>
  <mat-form-field class="col-12" appearance="outline">
    <mat-label>Seleccione el día</mat-label>
    <mat-select placeholder="Seleccione el dia" class="full-width-select" [(ngModel)]="metadata.tourScheduleSelectedToCopy.main.uuid"  (ngModelChange)="daySelectedToCopy();">
      <mat-option *ngFor="let object of daysAvaiblable" [value]="object.main.uuid">
        <span >{{object.main.day}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>

<mat-card>
  <mat-card-content>
    <h2 class="example-h2">Copiar en:</h2>

    <section class="example-section">
      <mat-checkbox class="example-margin" *ngFor="let day of daysList" [(ngModel)]="day.selected" [disabled]="day.name == metadata.tourScheduleSelectedToCopy.main.day" >{{day.name}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-checkbox>
    </section>
  </mat-card-content>
</mat-card>

<mat-dialog-actions class="row" align="end">
  <button mat-button mat-dialog-close class="btn btn-danger col-3" (click)="close(false);">Cancelar</button>&nbsp;&nbsp;
  <button mat-button class="btn btn-primary col-3" (click)="startCopyScheduls();" >Copiar</button>
</mat-dialog-actions>
