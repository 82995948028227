<div class="text-center tittle-menu">
  <img class="pt-1 pb-1"src="/assets/img/logo.png" style="width: 10rem;">
</div>
<div class="row justify-content-center global-menu">
  <div class="col-12">
    <div class="list-group">
      <a class="list-group-item" routerLink="{{object.route}}" *ngFor="let object of menuOptions">
        <!-- <p class="mb-0 p-2 row text-uppercase"> -->
          <img src="{{object.icon}}" style="width: 2.5rem;">
          <span class="pt-2">{{object.title}}</span>
        <!-- </p> -->
      </a>
      <!-- <a class="list-group-item " style="width: 104%;" routerLink="/admin/setting">
        <p class="mb-0 p-2 row text-uppercase">
          <img src="/assets/img/menu/icono-setting.png" style="width: 2.8rem;">
          <span class="pt-2">Configuración</span>
        </p>
      </a> -->
    </div>
  </div>
</div>
