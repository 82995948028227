<h1 mat-dialog-title class="mt-2">Empresa de trabajo</h1>
<hr />
<div mat-dialog-content class="row justify-content-center mt-2">
  <mat-form-field class="col-12" appearance="outline">
    <mat-label>Empresas</mat-label>
    <mat-select [(ngModel)]="metadata.object.main.uuid" (ngModelChange)="getStates(metadata.object.main.uuid)">
      <mat-option *ngFor="let object of objects" [value]="object.system.objectId">
        <span *ngIf="object.system.object.main.tradename">{{object.system.object.main.tradename}}</span>
        <span *ngIf="object.system.object.main.name">{{object.system.object.main.name}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-12" appearance="outline" *ngIf="metadata.showState">
    <mat-label>Estado</mat-label>
    <mat-select [(ngModel)]="metadata.state.main.uuid" (ngModelChange)="stateSelected();">
      <mat-option *ngFor="let object of stateList" [value]="object.system.state.main.uuid">
        <span >{{object.system.state.main.name}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
<div mat-dialog-actions class="row justify-content-center">

  <!-- <button mat-button (click)="close(false)" cdkFocusInitial>Cerrar</button> -->
    <button class="col-6" mat-raised-button color="accent" [disabled]="metadata.object.main.uuid  == ''" (click)="close(true)" *ngIf="!metadata.showState">Seleccionar</button>
    <button class="col-6" mat-raised-button color="accent" [disabled]="metadata.object.main.uuid  == '' || metadata.state.main.uuid == ''" (click)="close(true)" *ngIf="metadata.showState">Entrar</button>

</div>
