import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-admin-sale',
  templateUrl: './admin-sale.component.html',
  styleUrls: ['./admin-sale.component.css']
})
export class AdminSaleComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  saleMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    main: {
      status: 'Parcialmente'
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  saleList = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(public loadding:LoadingService, protected session: SessionService, public snackBar: MatSnackBar) {
    this.saleMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.saleList = [];
        this.saleMin.filter.name = '';
      }else{
        this.saleList = [];
        this.saleMin.filter.name = '';
        this.saleMin.filter.value = '';
        val = 'false'
      }
      this.getToursList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit(): void {
    this.getToursList();
  }

  /**funcionalidad para obetner el listado de tours
  **/
  getToursList(){
    this.saleList = [];
    this.loadding.wait();
    this.session.getRequest("sale:findAllByCompanyAndStatus",this.saleMin).subscribe(
      (data:any) => {
        this.saleList = data.object.instanceList;
        this.paginator.total = data.object.total;
        this.loadding.hide();
     },
     error => {
       console.log("sale:findAllByCompanyAndStatus",error);
       this.loadding.hide();
     }
    );
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.saleMin.filter.value);
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.saleMin.offset = (event.pageIndex * event.pageSize);
    this.saleMin.max = event.pageSize;
    this.getToursList();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
