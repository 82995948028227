<div class="row">
  <div class="col-12">
    <mat-card style="border-left:solid #0395ce 5px;">
      <p>Me gusto mucho el lugar .....</p>
    </mat-card>
  </div>
  <div class="col-12 mt-2">
    <mat-card style="border-left:solid #FF6600 5px;">
      <p>No me gusto mucho el lugar .....</p>
    </mat-card>
  </div>
</div>
