<mat-drawer-container class="drawerConteiner" >
    <mat-drawer #drawer mode="side" class="mainDrawer">
      <app-admin-menu></app-admin-menu>
    </mat-drawer>
    <mat-drawer-content class="drawerContent">
      <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
      <div class="row justify-content-center">
        <div class="col-10 text-right">
          <div class="row mt-2 mb-2 justify-content-end">
            <div class="col-9 text-left">
              <h3 class="text-uppercase pt-2 pb-2">Agregar imagenes</h3>
            </div>
            <div class="col-3">
                <button type="button" class="btn btn-danger btn-block" routerLink="/admin/events">Regresar</button>
            </div>
          </div>
        </div>
        <div class="col-10">
          <mat-card class="text-left">
            <div class="row">
              <div class="col-6">
                <p>Evento: <strong>{{object.main.name}}</strong> </p>
                <p>Estado: <strong>{{object.system.state.main.name}}</strong> </p>
              </div>
              <div class="col-12">
                <keysoft-upload-events [uploadCode]="'eventImage:upload'" [downloadCode]="'eventImage:download'" [listFilesCode]="'eventImage:findAllByEvent'" [objectUuid]="object.main.uuid"  [deleteCode]="'eventImage:delete'"  [editable]="true" [oneFileUploated]="false"></keysoft-upload-events>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
