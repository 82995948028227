<div *ngIf="enabled" class="overlay">
  <div class="boxLogo">
    <div class="keysoftLogo">
      <div class="lds-roller" style="margin: 0px 40%;"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <label class="boxMsg">{{msg}}</label>
  </div>
</div>
<div *ngIf="whiteEnable" class="white-overlay sti-dark">
  <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
</div>
