import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SessionService } from '../service/session/session.module';
import { LoadingService } from '../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectedObjectComponent } from './selected-object/selected-object.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '../admin/admin-menu/menu.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  complexForm: FormGroup;
  object = {
    main: {
      username:'',
      password:''
    }
  }
  metadata = {
    remember:false
  }

  constructor(private router: Router, public fb: FormBuilder, private snackBar: MatSnackBar, public loading:LoadingService, private session:SessionService, public dialog: MatDialog, public menuService:MenuService) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'username': [null, Validators.required],
      'password': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    let username = localStorage.getItem('username');

    if(username){
      this.object.main.username = localStorage.getItem('username');
      this.object.main.password = localStorage.getItem('password');
      this.metadata.remember = JSON.parse(localStorage.getItem('remember'));
    }
  }

  /** funcion paa enviar el usuario y cotraseña
  */
  async send(object){
    this.loading.show(true,'Iniciando sesion');
    // Mandamos a invocar el servicio de login
    this.session.login(object).then( async (data:any)=> {
      if (data.transaction === 'ok') {
        // verificamos si se va a guadar las variables de sesión
        if(this.metadata.remember){
          localStorage.setItem('username', this.object.main.username);
          localStorage.setItem('password', this.object.main.password);
          localStorage.setItem('remember', JSON.stringify(this.metadata.remember));
        }else{
          localStorage.clear();
        }
        this.loading.hide();
        let role = this.session.getRoles()[0];
        let status = false;
        switch (role) {
          case 'ROLE_MANAGER':
          case 'ROLE_ADMINISTRATION':
          this.menuService.setMenuType('admin');
          status = await this.openSelectObject();
          if(status){
            this.router.navigate(['/admin']);
          }else{
            this.snackBar.open('Se cancelo el inicio de sesión.', 'Error', {duration: 5000});
            this.session.logout();
          }
          break;
          default:
          this.snackBar.open('Hola, buen dia. Por el momento no tenemos un dashboard para tu usuario.', 'Error', {duration: 5000});
            break;
        }
      } else {
        this.loading.hide();
        this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
        // console.log('Usuario o Contraseña incorrectos')
      }
    }).catch((error)=>{
      this.loading.hide();
      this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
      console.log('Error: ', error);
    });
  };


  openSelectObject():Promise<boolean>{
    return new Promise((resolve)=>{
      let objects = this.session.getObjects();
      if(objects.length > 0){
        // Abrimos un cuadro de dialogo para selecionar el objeto.
        const dialogRef = this.dialog.open(SelectedObjectComponent, {
          width: '30%',
          data: objects,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto seleccionado
              this.session.setObjectSession(result.object);
              resolve(true);
            }else{
              // El modal se cerro sin objeto seleccionado con boton cerrar
              resolve(false);
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            resolve(false);
          }
        });
      }else{
        // seleccionamos automaticamente el objeto
        this.session.setObjectSession(objects[0].system.object);
        resolve(true);
      }
    })
  }

}
