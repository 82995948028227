import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-admin-package-add-image',
  templateUrl: './admin-package-add-image.component.html',
  styleUrls: ['./admin-package-add-image.component.css']
})
export class AdminPackageAddImageComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  packageModel = {
  	main: {
  		uuid: '',
  		name: '',
  		lodging: false,
  		minimumPerson: 0,
  		pricePerson: 0.0,
  		secludedPerson: 0.0,
  	},
  	complement: {
  		description: '',
  		contactNumber: '',
  		contactEmail: ''
  	},
  	system: {
  		tours: [],
  		// packageImage: [],
  		// packageVideos: [],
  		company: {
  			main: {
  				uuid: '',
  			}
  		},
  		state: {
  			main: {
  				uuid: ''
  			}
  		}
  	}
  };
  packageVideoModel = {
    	main: {
    		uuid: '',
    		url: ''
    	},
    	system: {
    		package: {
    			main: {
    				uuid: ''
    			},
    		}
    	}
  }
  object = JSON.parse(JSON.stringify(this.packageModel));
  videoList = [];
  metadata = {
    packageVideo:JSON.parse(JSON.stringify(this.packageVideoModel))
  }
  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public loading:LoadingService, public fb: FormBuilder) {
    this.complexForm = fb.group({
      'url': [null, Validators.compose([Validators.required])],     
    });
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params)=>{
      this.object.main.uuid = params['uuid'];
      this.loading.show(true,"Espere un momento...")
      this.session.getRequest("package:get",this.object).subscribe((data:any)=>{
        this.object = data.object;
        this.session.getRequest("packageVideo:findAllByPackage",{system:{package:{main:{uuid:this.object.main.uuid}}}}).subscribe((data:any)=>{
          this.videoList = data.object.instanceList;
          this.loading.hide();
        },error=>{
          console.log('packageVideo:findAllByPackage',error);
          this.loading.hide();
        })
        this.loading.hide();
      },error=>{
        console.log('package:get',error);
        this.loading.hide();
      })
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  /**funcionalidad para agregar un video
  */
  addVideo(){
    let aux =  JSON.parse(JSON.stringify(this.metadata.packageVideo));
    aux.system.package.main.uuid = this.object.main.uuid;
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("packageVideo:update",aux).subscribe((data:any)=>{
      this.videoList.push(data.object);
      this.metadata.packageVideo = JSON.parse(JSON.stringify(this.packageVideoModel));
      this.loading.hide();
    },error=>{
      console.log('packageVideo:update',error);
      this.loading.hide();
    })
  }

  /**funcionalidad para eliminar urls de video
  */
  deleteAVideo(object){
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("packageVideo:delete",object).subscribe((data:any)=>{
      for(let i = 0; i < this.videoList.length; i++){
        if(this.videoList[i].main.uuid == object.main.uuid){
          this.videoList.splice(i,1);
          break;
        }
      }
      this.loading.hide();
    },error=>{
      console.log('packageVideo:delete',error);
      this.loading.hide();
    })
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
