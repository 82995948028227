<mat-drawer-container class="drawerConteiner">
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Agregar itinerario de actividades </h3>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-danger btn-block" routerLink="/admin/package">Regresar</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-left">
          <div class="row">
            <div class="col-6">
              <p>Nombre del paquete: <strong>{{object.main.name}}</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                <strong>{{object.system.state.main.name}}</strong> </p>
              <p>Nombre del paquete en inglés: <strong>{{object.main.nameEn}}</strong> </p>
              <p>Precio (MXN): <strong>{{object.main.pricePerson | currency:'CAD':'symbol-narrow'}} </strong></p>
              <p>Precio (USD): <strong>{{object.main.pricePersonEn | currency:'CAD':'symbol-narrow'}}</strong></p>
            </div>
          </div>
        </mat-card>
      </div>
      <hr class="col-12">

      <div class="col-10">
        <mat-card class="text-center">
          <div class="row mt-4 mb-3">
            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Itinerario</p>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Número de día (primer día)</mat-label>
                  <input matInput placeholder="Número de día" name="mNumberDay" rows="4" cols="80"
                    [formControl]="complexForm.controls['mNumberDay']" [(ngModel)]="objectPacket.main.numberDay">
                  <mat-error *ngIf="complexForm.controls['mNumberDay'].hasError('required')">Campo requerido
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Descripción de actividades</mat-label>
                  <textarea matInput placeholder="Descripción de actividades" name="mDescription" rows="4" cols="80"
                    [formControl]="complexForm.controls['mDescription']"
                    [(ngModel)]="objectPacket.main.description"></textarea>
                  <mat-error *ngIf="complexForm.controls['mDescription'].hasError('required')">Campo requerido
                  </mat-error>
                </mat-form-field>

                <div class="row justify-content-center mt-4">
                  <div class="col-4">
                    <button type="button" class="btn btn-danger btn-block" (click)="send()">Guardar</button>
                  </div>
                </div><br>
              </div>

            </div>
            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Descripción de actividades por día</p>

                <div class="col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">N° Día</th>
                        <th scope="col">Descripción</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let object of itinerario">
                        <td>{{object.main.numberDay}}</td>
                        <td><pre [innerHTML]="object.main.description" class="abstractDetail"></pre></td>
                        <td>
                          <button class="mr-2" color="primary" mat-icon-button matTooltip="Editar" (click)="editIten(object);">
                            <i class="material-icons">mode_edit</i>
                          </button>
                          <button class="mr-2" mat-icon-button matTooltip="Eliminar" (click)="delete(object);">
                            <span class="material-icons" style="color:#f05a24; cursor:pointer;"  matTooltip="Eliminar">delete_outline</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
                  <div class="col-6 ">
                    <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions
                      (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
                    </mat-paginator>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
