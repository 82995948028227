<mat-drawer-container class="drawerConteiner" >
    <mat-drawer #drawer mode="side" class="mainDrawer">
      <app-admin-menu></app-admin-menu>
    </mat-drawer>
    <mat-drawer-content class="drawerContent">
      <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
      <div class="row justify-content-center">
        <div class="col-10 text-right">
          <div class="row mt-2 mb-2 justify-content-end">
            <div class="col-9 text-left">
              <h3 class="text-uppercase pt-2 pb-2">Listado de Periodos por temporada</h3>
            </div>
            <div class="col-3">
                <button type="button" class="btn btn-danger btn-block" routerLink="/admin/periodo/update/new">Agregar Periodo</button>
            </div>
          </div>
        </div>
        <div class="col-10">
          <mat-card class="text-center">
            <mat-card-content>
              <div class="row">
                <div class="col-12">
                  <table class="table">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Temporada</th>
                        <th scope="col">Fecha de inicio del periodo</th>
                        <th scope="col">Fecha final del periodo</th>
                        <th scope="col">ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let object of dateTypeList">
                          <th>
                            {{object.main.seasonType ? 'ALTA':'BAJA'}}
                          </th>
                        <th>
                          {{object.main.startPeriod | date: 'dd/MM/yyyy'}}
                        </th>
                        <th>
                          {{object.main.endPeriod | date: 'dd/MM/yyyy'}}
                        </th>                        
                        <td>
                          <button class="mr-2" color="primary" mat-icon-button matTooltip="Editar" routerLink="/admin/periodo/update/{{object.main.uuid}}">
                            <i class="material-icons">mode_edit</i>
                          </button>
  
                          <button class="mr-2" color="primary" mat-icon-button matTooltip="Agregar Hospedaje" routerLink="/admin/hospedaje/addHospedaje/{{object.main.uuid}}">
                            <i class="material-icons">apartment</i>
                          </button>                       
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
                <div class="col-6 ">
                  <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
                  </mat-paginator>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  
