<mat-toolbar>
  <mat-toolbar-row class="toolbar">
    <div class="row" style="width:100%;">
      <div class="col-2">
        <button mat-mini-fab aria-label="Menu" matTooltip="Menu" (click)="toogleMenu();" color="warn">
          <mat-icon *ngIf="!metadata.toggleMenu">menu</mat-icon>
          <mat-icon *ngIf="metadata.toggleMenu">menu_open</mat-icon>
        </button>
      </div>
      <div class="col-7 text-center" >
      </div>
      <div class="col-3 p-0 text-right">
        <button mat-button [matMenuTriggerFor]="menu" class="userToolbar">
          <span class="text-uppercase">Administrador &nbsp;|&nbsp; </span>
          <span class="material-icons">person_outline</span>
          <!-- <span>{{metadata.object.main.tradename}}</span> -->
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <!-- <img src="assets/img/perfil.png" style="width:1.5rem"> -->
            <span>Perfil</span>
          </button>
          <button mat-menu-item (click)="salir();">
            <!-- <img src="assets/img/salir.png" style="width:1.5rem"> -->
            <span>Salir</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
