<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Nuevo Usuario</h3>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-danger btn-block" routerLink="/admin/setting">Regresar</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-center">
          <div class="row mt-4">
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>&nbsp;Nombre de usuario</mat-label>
              <input matInput placeholder="Nombre de usuario" name="mName">
            </mat-form-field>
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>&nbsp;Correo electrónico</mat-label>
              <input matInput placeholder="Correo electrónico" name="mName">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-9" appearance="outline">
              <mat-label>Estados</mat-label>
              <input matInput placeholder="Estados" name="mState">
            </mat-form-field>
            <div class="col-3">
              <button mat-raised-button color="primary" style="width:100%;">
                <mat-icon>backup</mat-icon>
              </button>
            </div>
          </div>
          <div class="row justify-content-end mt-4">
            <div class="col-4">
              <button type="button" class="btn btn-danger btn-block" >Guardar</button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
