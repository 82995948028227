import { Input, HostListener, OnInit, Component, ChangeDetectionStrategy, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from 'ngx-uploader';

export interface images {
  uuid:string,
  name:string,
  file:string,
  index:number,
  total:number,
  chunk:number,
  chunkSize:number,
  position:number,
  type:string,
  upload:boolean,
  uploaded:boolean,
  mode:string,
  valueProcess:number,
  uuidFile:string
}
interface fileUpload {
  file:{
    uuid:string,
    name:string,
    file:string,
    index:number,
    total:number,
    chunk:number,
    chunkSize:number,
    position:number,
    type:string,
    upload:boolean,
    uploaded:boolean,
    mode:string,
    valueProcess:number,
    uuidFile:string
  }
}
@Component({
  selector: 'keysoft-upload',
  templateUrl: './keysoft-upload.html',
  styleUrls: ['./keysoft-upload.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KeysoftUploadComponent {
  // @ViewChild("fileGet", {static: false}) fileGet: ElementRef;
  // @Input('stiFile') stiFile: images;
  @Input('multiple') multiple: boolean;
  @Input('uploadCode') uploadCode: string;
  @Input('downloadCode') downloadCode: string;
  @Input('objectUuid') objectUuid: string;
  @Input('listFilesCode') listFilesCode: string;
  @Input('deleteCode') deleteCode: string;
  @Input('editable') editable: boolean;
  @Input('oneFileUploated') oneFileUploated: boolean;

  files = [];

  metadata = {
    value:0,
    mode:'buffer'
  }
  fileUploadModel:fileUpload = {
    file:{
      uuid:'',
      name:'',
      file:'',
      index:0,
      total:0,
      chunk:0,
      chunkSize:0,
      position:0,
      type:'',
      upload:false,
      uploaded:false,
      mode:'buffer',
      valueProcess:0,
      uuidFile:''
    }
  };
  images:images = {
    uuid:'',
    name:'',
    file:'',
    index:0,
    total:0,
    chunk:0,
    chunkSize:0,
    position:0,
    type:'',
    upload:false,
    uploaded:false,
    mode:'buffer',
    valueProcess:0,
    uuidFile:''
  }


  stiFile:images = JSON.parse(JSON.stringify(this.images));
  filesAux: UploadFile[] = [];
  options: UploaderOptions;
  uploadInput: EventEmitter<UploadInput>;

  constructor(protected session:SessionService, public snackBar: MatSnackBar) {
    this.options = { concurrency: 1, maxUploads: 5, maxFileSize: 10000000 };
    console.log("multiple",this.multiple);
    console.log("uploadCode",this.uploadCode);
    console.log("downloadCode",this.downloadCode);
    console.log("objectUuid",this.objectUuid);
    console.log("oneFileUploated",this.oneFileUploated);
  }
  ngOnInit(){

  }

  ngOnChanges(changes: ChangeDetectionStrategy) {
    // changes.prop contains the old and the new value...
    if(this.objectUuid != ''){
      this.getFilesFromDB();
    }
  }
  ngDoCheck(){
    if(this.objectUuid == '' && this.files.length > 0){
      this.files = [];
    }
  }

  /**Método para obtener el archivo en base64
  */
  getFile(file){
    console.log(file)
    let imageAux = JSON.parse(JSON.stringify(this.images));
    imageAux.type = file.type;
    imageAux.name =  this.colorHEX()+file.name;
    imageAux.name =  this.getImageText(imageAux.name);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event:any) => {
      imageAux.file = event.target.result;
      console.log(":::::imageAux:::::",imageAux);
      this.uploadFile(imageAux);
      this.files.push(imageAux);
    };

    // const fileUpload = this.fileGet.nativeElement;
    // fileUpload.onchange = () => {
    //   // console.log("::::::",fileUpload.files);
    //   console.log(fileUpload.files[0]);
    //  // this.uploadFile(this.fileModel);
    //  this.stiFile.name = fileUpload.files[0].name;
    //  this.stiFile.type = fileUpload.files[0].type;
    //  let file = fileUpload.files[0];
    //  let reader = new FileReader();
    //  reader.readAsDataURL(file);
    //  reader.onload = (event:any) => {
    //    this.stiFile.file = event.target.result
    //    console.log("this.stiFile",this.stiFile);
    //    //agregamos el file  a el arreglo de files
    //    let fileAux = JSON.parse(JSON.stringify(this.images));
    //    fileAux = JSON.parse(JSON.stringify(this.stiFile));
    //    this.files.push(fileAux);
    //    this.cleanButonStiFile();
    //  };
    //
    // };
    // fileUpload.click();
  }

  /**Método para subir el archivo a la Base de Datos
  */
  uploadFile(object){
    if(this.objectUuid && this.objectUuid != ''){
      if(this.uploadCode){
        object.upload = true;
        let auxFile = object.file.replace(/\+/g,"-");
        let chunksSize = 7000;
        let chunks = Math.ceil(object.file.length/chunksSize)-1;
        // console.log(this.stiFile);

        object.file = auxFile;
        object.chunk = chunks;
        object.chunkSize = chunksSize;
        object.total = object.file.length;
        object.mode = 'determinate';
        object.valueProcess = 0;
        object.index = 0;
        object.position = 0;
        object.uuid = this.objectUuid;
        this.uploadFileToBD(this.uploadCode,object).subscribe((data:any)=>{
          // console.log("DATOS",data);
          object.valueProcess = 100;
          object.uploaded = true;
          object.upload = false;
        },error=>{
          console.log("Error",error);
          this.cleandBuffer(object);
          this.snackBar.open("No se logro subir el archivo... intente nuevamente", 'Error', {duration: 5000});
        });
      }else{
        this.snackBar.open("Ingrese un endpoint para subir a la BD", 'Infomación', {duration: 5000});
      }
    }else{
      this.snackBar.open("Verifique que el padre del archivo ya este guardado antes de subir el archivo", 'Infomación', {duration: 5000});
    }
  }


  /*********** --- UTILIDADES --- ************/
  uploadFileToBD(code: string, object:any): Observable<Response> {

    return Observable.create(async(observer) => {

      try{
        let objectToSend:fileUpload = {
          file:{
            uuid:'',
            name:'',
            file:'',
            index:0,
            total:0,
            chunk:0,
            chunkSize:0,
            position:0,
            type:'',
            upload:false,
            uploaded:false,
            mode:'buffer',
            valueProcess:0,
            uuidFile:''
          }
        };
        objectToSend.file = JSON.parse(JSON.stringify(object));
        objectToSend.file.file = object.file;
        console.log("object",object);
        console.log("objectToSend",objectToSend);
        let resp = await this.uploadFileByParts(code,objectToSend,object);
        console.log(resp);
        if(resp.status){
          console.log("Terminado");
          this.oneFileUploated = true;
          observer.next();
          observer.complete();
        }else{
          console.log("Sin terminar");
        }
      }catch(e){
        observer.error(e);
      }

    });
  }

  /**función para subir base64 por partes
  */

  uploadFileByParts(code: string, object:fileUpload, images:images):Promise<{status:boolean,object:any}>{
    return new Promise(async(resolve,reject)=>{
      if(object.file.position < object.file.total){
        try{
          //partimos el base64 en varias partes de acuerdo el numero de trozos caracteres
          let lengthMin = object.file.position;
          let lengthMax = object.file.position + object.file.chunkSize > object.file.total ? object.file.total : object.file.position + object.file.chunkSize;

          console.log("=== Trozos ===",lengthMin +'/'+lengthMax);
          //se envia el file en segmentos.
          let aux:fileUpload = object;
          aux.file.file = images.file.slice(lengthMin, lengthMax); // prestar atención aqui
          this.session.getRequest(code,aux).subscribe(async(data:any)=>{
            console.log("HHHHHHHHHH",data);
            // images.uuidFile = data.object.main.uuid;
            object.file.position = object.file.position + object.file.chunkSize;
            object.file.index++;
            this.stiFile.index++;// para llevar el numero de petición actual
            this.changeLoadder(object,images);
            let resp = await this.uploadFileByParts(code,object,images);
            resp.object = data.object;
            resolve(resp);
          },error=>{
            reject(error);
          })
        }catch(e){
          reject(e);
        }
      }else{
        // como llegamos al limite de enviamos en el
        resolve({
          status:true,
          object:{}
        })
      }
    });
  }
  /*********** --- FIN --- ************/

  /**función para tenerminar el valor de la carga
  */
  changeLoadder(fileUpload:fileUpload,object:images){
    object.index = fileUpload.file.index;
    object.chunk = fileUpload.file.chunk;
    object.valueProcess = (object.index/object.chunk)*100;
  }

  /**clenad buffer to upload
  */
  cleandBuffer(object){
    object.mode = 'buffer';
    object.valueProcess = 0
    object.upload = false;
  }

  /** clean Data
  */
  cleanButonStiFile(){
    this.stiFile = JSON.parse(JSON.stringify(this.images));
  }


  /**método para obtener el listado de archivos
  *** Esta parte debe cambiarse para que este dinamico. y no fijo el object de la petición
  */
  getFilesFromDB(){
    this.files = [];
    this.session.getRequestAnonymous(this.listFilesCode,{system:{tour:{main:{uuid:this.objectUuid}}}}).subscribe((data:any)=>{
      console.log('listFilesCode',data.object);
      for(let item of data.object.instanceList){
        let aux:images = JSON.parse(JSON.stringify(this.images));
        aux.name = item.image.fileName;
        aux.type = item.image.fileType;
        aux.uuidFile = item.main.uuid;
        aux.uuid = this.objectUuid;
        aux.mode = 'determinate';
        aux.valueProcess = 100;
        aux.uploaded = true;
        this.files.push(aux);
      }

    },error=>{
      console.log(error);
    })
  }

  /*
  funcion para descargar un archivo.
  */
  async downloadAFile(object){
    // console.log(object);
    let elem = document.createElement('a');
    elem.href = "https://turismo.keysoft.mx/assets/tour/"+object.name;
    elem.setAttribute('download', object.name);
    elem.setAttribute('target', '_blank');
    document.body.appendChild(elem);
    elem.click();
  }

  /**funcionalidad para pedir a la bd el base
  */
  getRequestBaseFile(object):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.session.getRequest(this.downloadCode,object).subscribe((data:any)=>{
        resolve(data);
      },error=>{
        reject(error);
      })
    })
  }

  /**Método para eliminar un file
  **/
  deleteAFile(object){
    for(let j = 0; j < this.filesAux.length; j++){
      if(this.filesAux[j].name == object.name){
        this.filesAux.splice(j,1);
      }
    }
    if(object.uuidFile != ''){
      this.session.getRequest(this.deleteCode,{main:{uuid:object.uuidFile}}).subscribe((data:any)=>{
        for(let i=0; i < this.files.length; i++){
          if(this.files[i].name == object.name){
            this.files.splice(i,1);
          }
        }
      },error=>{
        console.log(error);
      })
    }else{
      for(let i=0; i < this.files.length; i++){
        if(this.files[i].name == object.name){
          this.files.splice(i,1);
        }
      }
    }
  }


  async onUploadOutput(output: UploadOutput) {
    if (output.type === 'allAddedToQueue') {
      for(let item of this.filesAux){
        let nativeFileAux = item.nativeFile;
        await this.getFile(nativeFileAux);
      }
      this.filesAux = [];
      console.log("Archivos listos para subir::::::::::::>",this.files);
    }
    else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.filesAux.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.filesAux.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.filesAux[index] = output.file;
    } else if (output.type === 'cancelled' || output.type === 'removed') {
      this.filesAux = this.filesAux.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      // this.dragOver = true;
    } else if (output.type === 'dragOut') {
      // this.dragOver = false;
    } else if (output.type === 'drop') {
      // this.dragOver = false;
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      console.log(output.file.name + ' rejected');
      if(output.file.name){
        this.filesAux.push(output.file);
      }
    }
    console.log(">>>>>>>",output);

    // this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
  }

  getImageText(object){
    object = object.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return object.replace(/ /g, "_");
  }

  generarLetra(){
    var letras = ["a","b","c","d","e","f","0","1","2","3","4","5","6","7","8","9"];
    var numero = (Math.random()*15).toFixed(0);
    return letras[numero];
  }
  colorHEX(){
    var coolor = "";
    for(var i=0;i<6;i++){
      coolor = coolor + this.generarLetra() ;
    }
    return coolor;
  }
}
