import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { AdminPlacesVisitedCommentaryComponent } from './admin-places-visited-commentary/admin-places-visited-commentary.component';

@Component({
  selector: 'app-admin-places-visited',
  templateUrl: './admin-places-visited.component.html',
  styleUrls: ['./admin-places-visited.component.css']
})
export class AdminPlacesVisitedComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  /**
   * Modal para dar de alta un almacen
   */
  commentaryUpdate() {
    this.dialog.open(AdminPlacesVisitedCommentaryComponent, {
      width: '50%',
      height:'40rem'
    }).afterClosed().subscribe(result => {

    });
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
