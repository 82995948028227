import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService  } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AdminCopyToursComponent } from '../../../admin/admin-tours/admin-copy-tours/admin-copy-tours.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-tours-update',
  templateUrl: './admin-tours-update.component.html',
  styleUrls: ['./admin-tours-update.component.css']
})
export class AdminToursUpdateComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  tourModel = {
    main: {
      uuid: '',
      name: '',
      nameEn: '',//Nombre del tour en Ingles
      children: false,
      minimumPerson: 1,
      secludedPerson: 0.0,
      pricePerson: 0.0,
      pricePerChild: 0.0,
      secludedPersonEn: 0.0,//Apartado por persona en dólares
      pricePersonEn: 0.0,//precio por persona en dólares
      pricePerChildEn: 0.0//precio por ñino en dólares
    },
    complement: {
      description: '',
      descriptionEn: ''//description
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      tourSchedules: [],
      state: {
        main: {
          uuid: ''
        }
      },

    }
  };

  tourScheduleModel = {
    main: {
      uuid: '',
      day: ''
    },
    system: {
      tour: {
        main: {
          uuid: ''
        }
      },
      placeDepartures: []
    }
  }

  placeDepartureModel = {
    main: {
      uuid: '',
      address: '',
      hour: 0,
      minute: 0
    },
    system: {
      tourSchedule: {
        main: {
          uuid: ''
        }
      }
    }
  }
  daysList = [{
    name: 'Domingo',
    value: 0,
    selected: true,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Lunes',
    value: 1,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Martes',
    value: 2,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Miercoles',
    value: 3,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Jueves',
    value: 4,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Viernes',
    value: 5,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }, {
    name: 'Sabado',
    value: 6,
    selected: false,
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    used: false
  }];

  object = JSON.parse(JSON.stringify(this.tourModel));
  stateList = [];
  tourSchedule = [];
  metadata = {
    tourSchedule: JSON.parse(JSON.stringify(this.tourScheduleModel)),
    placeDeparture: JSON.parse(JSON.stringify(this.placeDepartureModel)),
    generalDataEnglish: false,
    priceData: false
  }
  indexTAB=0;
  constructor(private session: SessionService, private router: Router, public activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, public loading: LoadingService, public fb: FormBuilder, public dialog: MatDialog) {
    this.object.system.state.main.uuid = this.session.getState().main.uuid;
    this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.stateList = this.session.getStateList();
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'secludedPerson': [null, Validators.compose([Validators.required])],
      'pricePerson': [null, Validators.compose([Validators.required])],
      'description': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] == 'new') {
        this.metadata.tourSchedule.main.day = 'Domingo';
        this.daysList[0].selected = true;
        this.metadata.tourSchedule = JSON.parse(JSON.stringify(this.daysList[0].tourSchedule));
        this.metadata.tourSchedule.main.day = this.daysList[0].name;
        this.metadata.tourSchedule.system.tour.main.uuid = this.object.main.uuid;
      } else {
        this.object.main.uuid = params['uuid'];
        this.loading.show(true, "Espere un momento...")
        this.session.getRequest("tour:get", this.object).subscribe((data: any) => {
          this.object = data.object;
          this.metadata.generalDataEnglish = this.object.main.nameEn ? true : false;
          this.metadata.priceData = this.object.main.pricePersonEn > 0 ? true : false;
          //cargamos los horarios
          for (let item of this.object.system.tourSchedules) {
            if (item.main.day == 'Domingo') {
              this.metadata.tourSchedule = JSON.parse(JSON.stringify(item));
            }
            for (let i = 0; i < this.daysList.length; i++) {
              if (item.main.day == this.daysList[i].name) {
                if(this.daysList[i].tourSchedule.main.uuid == ''){
                  this.daysList[i].tourSchedule = JSON.parse(JSON.stringify(item));
                }else{
                  for(let depa of item.system.placeDepartures){
                    this.daysList[i].tourSchedule.system.placeDepartures.push(depa);
                  }
                }
              }
              if (this.daysList[i].tourSchedule.system.placeDepartures.length > 0) {
                this.daysList[i].used = true;
              }else{
                this.daysList[i].used = false;
              }
            }
          }
          this.daysList[0].selected = true;
          this.metadata.tourSchedule = JSON.parse(JSON.stringify(this.daysList[0].tourSchedule));
          this.metadata.tourSchedule.main.day = this.daysList[0].name;
          this.metadata.tourSchedule.system.tour.main.uuid = this.object.main.uuid;
          this.loading.hide();
        }, error => {
          console.log('tour:get', error);
          this.loading.hide();
        })
      }
    }, error => {
      console.log("activatedRoute:error", error);
    })
  }

  async openCopiar() {
    await this.send(false);
    const dialogRef = this.dialog.open(AdminCopyToursComponent, {
      width: '36rem',
      data: this.object
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.loading.show(true, "Espere un momento...");
          //cargamos los horarios
          for (let item of result.object.system.tourSchedules) {
            for (let i = 0; i < this.daysList.length; i++) {
              if (item.main.day == this.daysList[i].name && item.system.placeDepartures.length > 0) {
                this.daysList[i].tourSchedule = JSON.parse(JSON.stringify(item));
              }
              if (this.daysList[i].tourSchedule.system.placeDepartures.length > 0) {
                this.daysList[i].used = true;
              }
            }
          }
          //actualizamos los datos de metadata para actualizar el dia actual.
          for (let i = 0; i < this.daysList.length; i++) {
            if (this.daysList[i].name == this.metadata.tourSchedule.main.day) {
              this.metadata.tourSchedule = JSON.parse(JSON.stringify(this.daysList[i].tourSchedule));
              this.metadata.tourSchedule.main.day = this.daysList[i].name;
              this.metadata.tourSchedule.system.tour.main.uuid = this.object.main.uuid;
            }
          }

          this.loading.hide();
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });

  }


  send(status) {
    return new Promise((resolve, reject) => {
      this.loading.show(true, "Espere un momento...");
      this.object.system.tourSchedules = [];
      for (let item of this.daysList) {
        if (item.tourSchedule.system.placeDepartures.length > 0) {
          this.object.system.tourSchedules.push(item.tourSchedule);
        }
      }
      // console.log(this.daysList);
      // console.log(this.object);
      this.session.getRequest("tour:update", this.object).subscribe((data: any) => {
        this.object.main.uuid = data.object.main.uuid;
        this.object.system.tourSchedules = data.object.system.tourSchedules;
        this.loading.hide();

        if (status) {
          this.router.navigate(['/admin/tours'])
        }else{
          for (let i = 0; i < this.daysList.length; i++) {
            this.daysList[i].tourSchedule = JSON.parse(JSON.stringify(this.tourScheduleModel));
            this.daysList[i].used = true;
            this.daysList[i].selected = false;
          }


          for (let item of this.object.system.tourSchedules) {
            for (let i = 0; i < this.daysList.length; i++) {
              if (item.main.day == this.daysList[i].name) {
                if(this.daysList[i].tourSchedule.main.uuid == ''){
                  this.daysList[i].tourSchedule = JSON.parse(JSON.stringify(item));
                }else{
                  for(let depa of item.system.placeDepartures){
                    this.daysList[i].tourSchedule.system.placeDepartures.push(depa);
                  }
                }
              }
              if (this.daysList[i].tourSchedule.system.placeDepartures.length > 0) {
                this.daysList[i].used = true;
              }else{
                this.daysList[i].used = false;
              }
            }
          }
          // console.log(this.indexTAB);
          console.log(this.daysList);
          this.tabChange({index:this.indexTAB});
        }
        resolve(true);
      }, error => {
        reject(false);
        console.log('tour:update', error);
        this.loading.hide();
      })
    })

  }
  /**funcionalidad para un tab
  */
  tabChange(ev) {
    // console.log(ev);
    this.indexTAB = ev.index;
    for (let i = 0; i < this.daysList.length; i++) {
      this.daysList[i].selected = false;
      if (ev.index == this.daysList[i].value) {
        // console.log(this.daysList[i]);
        this.daysList[i].selected = true;
        this.metadata.tourSchedule = JSON.parse(JSON.stringify(this.daysList[i].tourSchedule));
        this.metadata.tourSchedule.main.day = this.daysList[i].name;
        this.metadata.tourSchedule.system.tour.main.uuid = this.object.main.uuid;
      }
    }
  }

  /**funcionalidad para agregar salida y horario
  */
  async addDeparture() {
    if (this.metadata.placeDeparture.main.address != '') {
      // verificamos si es un Editar
      this.metadata.placeDeparture.system.tourSchedule.main.uuid = this.metadata.tourSchedule.main.uuid;
      let aux = JSON.parse(JSON.stringify(this.metadata.placeDeparture));
      // varificamos si esta salida es una salida
      this.metadata.tourSchedule.system.placeDepartures.push(aux);
      // actualizamos los datos del dia seleccionado.
      for (let i = 0; i < this.daysList.length; i++) {
        if (this.daysList[i].name == this.metadata.tourSchedule.main.day) {
          this.daysList[i].tourSchedule = JSON.parse(JSON.stringify(this.metadata.tourSchedule));
          this.daysList[i].used = true;
        }
      }
      this.metadata.placeDeparture = JSON.parse(JSON.stringify(this.placeDepartureModel));
      await this.send(false);
    }

  }

  /**funcionalidad para editar una salida
  */
  deleteDeparture(index) {
    for (let i = 0; i < this.daysList.length; i++) {
      for (let j = 0; j < this.daysList[i].tourSchedule.system.placeDepartures.length; j++) {
        // console.log('--', this.metadata.tourSchedule.system.placeDepartures[index]);
        if (this.daysList[i].tourSchedule.system.placeDepartures[j].main.uuid == this.metadata.tourSchedule.system.placeDepartures[index].main.uuid) {
          this.loading.show(true,"Espere un momento...");
          this.session.getRequest("placeDeparture:delete", this.daysList[i].tourSchedule.system.placeDepartures[j]).subscribe((data: any) => {
            this.daysList[i].tourSchedule.system.placeDepartures.splice(j, 1);
            this.metadata.tourSchedule.system.placeDepartures.splice(index, 1);
            if (this.daysList[i].tourSchedule.system.placeDepartures.length>0) {
              this.daysList[i].used = true;
            } else {
              this.daysList[i].used = false;
            }
            this.snackBar.open('Se elimina correctamente el horario', 'Información', {duration: 1000});
            this.loading.hide();
          }, error => {
            console.log('placeDeparture:delete', error);
            // El modal se cerro sin objeto
            this.snackBar.open('No fue posible elminar el registro', 'Error', {duration: 1000});
            this.loading.hide();
          });
          break;
        }
      }
    }
  }

  chageZero(sec){
    let strSec = String(sec);

    if (sec < 10) {
        strSec = '0' + strSec;
    }
    return strSec;
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
