<mat-drawer-container class="drawerConteiner">
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Nuevo Tour</h3>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-danger btn-block" routerLink="/admin/tours">Regresar</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-center">
          <div class="row mt-4 mb-3">
            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Datos del tour</p>
                <div class="col-12 text-left pb-1 ml-1">
                  <p>¿ El tour tiene datos en ingles ?
                    <mat-slide-toggle class="ml-2" name="mDE" [(ngModel)]="metadata.generalDataEnglish"> <span *ngIf="metadata.generalDataEnglish"> <strong>Si</strong> </span> <span *ngIf="!metadata.generalDataEnglish"> <strong>No</strong> </span></mat-slide-toggle>
                  </p>
                </div>
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>Estado</mat-label>
                  <mat-select required name="cMaritalStatus" class="full-width-select" [(ngModel)]="object.system.state.main.uuid">
                    <mat-option *ngFor="let object of stateList" [value]="object.system.state.main.uuid">
                      {{object.system.state.main.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Nombre de Tour</mat-label>
                  <input matInput placeholder="Nombre de Tour" name="mName" [formControl]="complexForm.controls['name']" [(ngModel)]="object.main.name">
                  <mat-error *ngIf="complexForm.controls['name'].hasError('required')">Campo requerido</mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Descripción</mat-label>
                  <textarea matInput placeholder="Descripción" name="mDescription" rows="4" cols="80" [formControl]="complexForm.controls['description']" [(ngModel)]="object.complement.description"></textarea>
                  <mat-error *ngIf="complexForm.controls['description'].hasError('required')">Campo requerido</mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline" *ngIf="metadata.generalDataEnglish">
                  <mat-label>&nbsp;Nombre de Tour en Inglés</mat-label>
                  <input matInput placeholder="Nombre de Tour en Inglés" name="mNameEn" [(ngModel)]="object.main.nameEn">
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline" *ngIf="metadata.generalDataEnglish">
                  <mat-label>&nbsp;Descripción en Inglés</mat-label>
                  <textarea matInput placeholder="Descripción en Inglés" name="mDescription En" rows="4" cols="80" [(ngModel)]="object.complement.descriptionEn"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Precios del tour</p>
                <div class="col-12 text-left pb-2 ml-1">
                  <p>¿ El tour tiene precios en dólares ?
                    <mat-slide-toggle class="ml-2" name="mDE" [(ngModel)]="metadata.priceData"> <span *ngIf="metadata.priceData"> <strong>Si</strong> </span> <span *ngIf="!metadata.priceData"> <strong>No</strong> </span> </mat-slide-toggle>
                  </p>
                </div>
                <div class="col-12 text-left pb-1 ml-1">
                  <p>¿ Viajan niños ?
                    <mat-slide-toggle class="ml-2" name="mChildren" [(ngModel)]="object.main.children"> <span *ngIf="!object.main.children"> <strong>No</strong> </span> <span *ngIf="object.main.children"> <strong>Si</strong> </span></mat-slide-toggle>
                  </p>
                </div>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                  <mat-label>&nbsp;Minimo de personas</mat-label>
                  <input matInput placeholder="Nombre de Tour" name="mMinimun" type="number" [(ngModel)]="object.main.minimumPerson">
                </mat-form-field>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                  <mat-label>&nbsp;Apartado por persona $ MXN</mat-label>
                  <input matInput placeholder="Apartador minimo" name="secludedPerson" type="number" [formControl]="complexForm.controls['secludedPerson']" [(ngModel)]="object.main.secludedPerson">
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="complexForm.controls['secludedPerson'].hasError('required')">Campo requerido</mat-error>
                  <mat-hint align="start"><strong>Ingrese el precio en MXN</strong></mat-hint>
                </mat-form-field><br><br>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                  <mat-label>&nbsp;Precio por persona</mat-label>
                  <input matInput placeholder="Precio por persona" name="pricePerson" type="number" [formControl]="complexForm.controls['pricePerson']" [(ngModel)]="object.main.pricePerson">
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="complexForm.controls['pricePerson'].hasError('required')">Campo requerido</mat-error>
                  <mat-hint align="start"><strong>Ingrese el precio en MXN</strong></mat-hint>
                </mat-form-field>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                  <mat-label>&nbsp;Precio por niño</mat-label>
                  <input matInput placeholder="Precio por niño" [disabled]="!object.main.children" name="pricePerChild" type="number" [(ngModel)]="object.main.pricePerChild">
                  <span matPrefix>$&nbsp;</span>
                  <mat-hint align="start"><strong>Ingrese el precio en MXN</strong></mat-hint>
                </mat-form-field><br><br>
                <mat-form-field class="col-12 col-md-6" appearance="outline" *ngIf="metadata.priceData">
                  <mat-label>&nbsp;Apartado por persona</mat-label>
                  <input matInput placeholder="Apartador minimo" name="secludedPerson" type="number" [(ngModel)]="object.main.secludedPersonEn">
                  <span matPrefix>$&nbsp;</span>
                  <mat-hint align="start"><strong>Ingrese el precio en USD</strong></mat-hint>
                </mat-form-field>
                <mat-form-field class="col-12 col-md-6" appearance="outline" *ngIf="metadata.priceData">
                  <mat-label>&nbsp;Precio por persona</mat-label>
                  <input matInput placeholder="Precio por persona" name="pricePerson" type="number" [(ngModel)]="object.main.pricePersonEn">
                  <span matPrefix>$&nbsp;</span>
                  <mat-hint align="start"><strong>Ingrese el precio en USD</strong></mat-hint>
                </mat-form-field><br><br>
                <mat-form-field class="col-12 col-md-6" appearance="outline" *ngIf="metadata.priceData">
                  <mat-label>&nbsp;Precio por niño</mat-label>
                  <input matInput placeholder="Precio por niño" [disabled]="!object.main.children" name="pricePerChild" type="number" [(ngModel)]="object.main.pricePerChildEn">
                  <span matPrefix>$&nbsp;</span>
                  <mat-hint align="start"><strong>Ingrese el precio en USD</strong></mat-hint>
                </mat-form-field>

              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-1">
              <button mat-raised-button color="primary" (click)="openCopiar()">
                <i class="fa fa-copy"></i> Copiar
              </button>
            </div>
            <mat-tab-group class="col-11" (selectedTabChange)="tabChange($event);" [selectedIndex]="indexTAB">
              <mat-tab *ngFor="let object of daysList">
                <ng-template mat-tab-label>
                  <!--<mat-icon class="example-tab-icon" *ngIf="object.used">check_circle</mat-icon>
                  <mat-icon class="example-tab-icon" *ngIf="!object.used">panorama_fish_eye</mat-icon>-->
                  <i class="fa fa-bus autobus fa-2x p-2" *ngIf="object.used"></i>
                  <i class="fa fa-bus fa-2x p-2" *ngIf="!object.used"></i>
                  {{object.name}}
                </ng-template>
              </mat-tab>
            </mat-tab-group>

            <section class="row col-12">
              <p class="col-12 text-left" style="padding: 15px;">Ingrese el lugar y horario de salida del tour.</p>
              <mat-form-field class="col-6" appearance="outline">
                <mat-label>&nbsp;Dirección</mat-label>
                <textarea matInput placeholder="Dirección" type="text" name="address" [(ngModel)]="metadata.placeDeparture.main.address"></textarea>
              </mat-form-field>
              <mat-form-field class="col-2" appearance="outline">
                <mat-label>&nbsp;Hora (24hrs)</mat-label>
                <input matInput placeholder="Hora (24hrs)" type="number" name="HrDeparture" [(ngModel)]="metadata.placeDeparture.main.hour">
              </mat-form-field>
              <mat-form-field class="col-2" appearance="outline">
                <mat-label>&nbsp;Minuto</mat-label>
                <input matInput placeholder="Minuto" type="number" name="minDeparture" [(ngModel)]="metadata.placeDeparture.main.minute">
              </mat-form-field>
              <div class="col-2">
                <button mat-raised-button color="primary" style="width:100%;" (click)="addDeparture();">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
              <hr class="col-12">
            </section>
          </div>

          <div class="row" *ngIf="metadata.tourSchedule.system.placeDepartures.length > 0">
            <div class="col-12">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre del Lugar</th>
                    <th>Horario</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let object of metadata.tourSchedule.system.placeDepartures; let i = index;">
                    <th>{{i+1}}</th>
                    <td>{{object.main.address}}</td>
                    <td>{{object.main.hour}}:{{chageZero(object.main.minute)}}</td>
                    <td>
                      <button class="mr-2" color="primary" mat-icon-button matTooltip="Editar" (click)="deleteDeparture(i);">
                        <i class="material-icons">delete</i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row justify-content-end mt-4">
            <div class="col-4">
              <button type="button" class="btn btn-danger btn-block" (click)="send(true);">Guardar</button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
