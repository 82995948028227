// import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef, Optional } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-admin-events-update',
  templateUrl: './admin-events-update.component.html',
  styleUrls: ['./admin-events-update.component.css']
})
export class AdminEventsUpdateComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  minDate = new Date();

  EventModel = {
    main: {
      uuid: '',
      name: '',
      nameEn: '',
      description: '',
      descriptionEn: '',
      apartDate:'',
      contact: '',
      contacNumber: '',
      contactEmail: '',
      numberPeople: 0,
      amount: 0.0,
      amountEn: 0.0
    },
    system: {
      state: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      }
    }
  };
  metadata = {
    apartDate: '',
    uploadted:false
  }
  stateList = [];
  
  object = JSON.parse(JSON.stringify(this.EventModel));

  // minDate: moment;

  constructor(private session: SessionService,
              private router:Router,
              public activatedRoute:ActivatedRoute,
              public snackBar: MatSnackBar,
              public loading:LoadingService,
              public fb: FormBuilder,
              ) {
                
    this.object.system.state.main.uuid = this.session.getState().main.uuid;
    this.object.system.company.main.uuid =  this.session.getSessionObject().main.uuid;
    this.stateList = this.session.getStateList();
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'vigency': [null, Validators.compose([Validators.required])],
      'description':[null, Validators.compose([Validators.required])],
      'numberPerson': [null, Validators.compose([Validators.required])],
      'phone': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      'contac_phone': [null, Validators.compose([Validators.required])],
      'priceEvent': [null, Validators.compose([Validators.required])],
    });

   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params)=>{
      if(params['uuid'] == 'new'){
      }else{
        this.object.main.uuid = params['uuid'];
        this.loading.show(true,"Espere un momento...")
        this.session.getRequest("event:get",this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.apartDate = moment(this.object.main.apartDate).toISOString();
          this.object = data.object;
          this.loading.hide();
        },error=>{
          console.log('event:get',error);
          this.loading.hide();
        })
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }


  send(){
    this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.loading.show(true,"Espere un momento...");
    console.log(this.object);
    this.object.main.apartDate = moment(this.metadata.apartDate).format('DD/MM/YYYY');
    this.session.getRequest("event:update", this.object).subscribe(async(data:any)=>{
      this.object.main.uuid = data.object.main.uuid;
      // console.log(this.object);
      this.loading.hide();
      this.router.navigate(['/admin/events'])
    },error=>{
      console.log('event:update',error);
      this.loading.hide();
    })
  }


  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
 
}
