import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-tours-add-image',
  templateUrl: './admin-tours-add-image.component.html',
  styleUrls: ['./admin-tours-add-image.component.css']
})
export class AdminToursAddImageComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  complexForm: FormGroup;

  tourModel = {
    main:{
      uuid:'',
      name:'',
      children:false,
      minimumPerson:1,
      secludedPerson:0.0,
      pricePerson:0.0,
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      tourSchedules:[],
      state:{
        main:{
          uuid:''
        }
      },

    }
  };
  object = JSON.parse(JSON.stringify(this.tourModel));

  tourVideoModel = {
      main: {
        uuid: '',
        url: ''
      },
      system: {
        tour: {
          main: {
            uuid: ''
          },
        }
      }
  }
  videoList = [];
  metadata = {
    tourVideo:JSON.parse(JSON.stringify(this.tourVideoModel))
  }
  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public loading:LoadingService,public fb: FormBuilder) {
    this.complexForm = fb.group({
      'url': [null, Validators.compose([Validators.required])],
    });
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params)=>{
      this.object.main.uuid = params['uuid'];
      this.loading.show(true,"Espere un momento...")
      this.session.getRequest("tour:get",this.object).subscribe((data:any)=>{
        this.object = data.object;
        this.session.getRequest("tourVideo:findAllByTour",{system:{tour:{main:{uuid:this.object.main.uuid}}}}).subscribe((data:any)=>{
          this.videoList = data.object.instanceList;
          this.loading.hide();
        },error=>{
          console.log('tourVideo:findAllByTour',error);
          this.loading.hide();
        })
        this.loading.hide();
      },error=>{
        console.log('tour:get',error);
        this.loading.hide();
      })
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  /**funcionalidad para agregar un video
  */
  addVideo(){
    let aux =  JSON.parse(JSON.stringify(this.metadata.tourVideo));
    aux.system.tour.main.uuid = this.object.main.uuid;
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("tourVideo:update",aux).subscribe((data:any)=>{
      this.videoList.push(data.object);
      this.metadata.tourVideo = JSON.parse(JSON.stringify(this.tourVideoModel));
      this.loading.hide();
      this.complexForm.markAsUntouched();
    },error=>{
      console.log('tourVideo:update',error);
      this.loading.hide();
    })
  }

  /**funcionalidad para eliminar urls de video
  */
  deleteAVideo(object){
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("tourVideo:delete",object).subscribe((data:any)=>{
      for(let i = 0; i < this.videoList.length; i++){
        if(this.videoList[i].main.uuid == object.main.uuid){
          this.videoList.splice(i,1);
          break;
        }
      }
      this.loading.hide();
    },error=>{
      console.log('tourVideo:delete',error);
      this.loading.hide();
    })
  }


  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
