import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { SessionService } from '../../service/session/session.service';
export interface menu {
    route: string
  }
@Injectable()
export class MenuService {
  object = {
    route:''
  };

  metadata = {
    typeMenu:''
  }

  menuChange: Subject<menu> = new Subject<menu>();
  constructor(public service:SessionService ) {
    this.menuChange.next(this.object);
   }

   selected(value){
     this.object.route = value;
     this.menuChange.next(this.object);
     sessionStorage.setItem('router', value);
   }
   getSubMenuSelected(){
     return Observable.create( observer => {
       observer.next(this.object.route)
     });
   }
   clean(){
     this.object.route = '';
     this.menuChange.next(this.object);
   }

   /** función para setear el tipo de menu a mostrar
   */
   setMenuType(object:string):void {
     sessionStorage.setItem('menuType', object);
     this.metadata.typeMenu = object;
   }

   /** funcion para obtener el tipo de menu a mostrar
   */
   getMenuType():string{
     this.metadata.typeMenu = sessionStorage.getItem('menuType');
     return this.metadata.typeMenu;
   }

   /** funcion para determinar el tipo de menu a rederizar
   */
   findMyMenu(){
     let typeMenu = this.getMenuType();
     let array = [];
     let companyUuid =  this.service.getSessionObject().main.uuid;
     switch(typeMenu){
       case 'admin':{
         array = [
           { route: '/admin', title:'Inicio', icon:'assets/img/menu/icono-home.png', open:false, options:[], selected:true },
           { route: '/admin/sale', title:'Ventas', icon:'assets/img/menu/icons-27.png', open:false, options:[], selected:false },
           { route: '/admin/tours', title:'Tours', icon:'assets/img/menu/icons-28.png', open:false, options:[], selected:false },
           { route: '/admin/package', title:'Paquetes', icon:'assets/img/menu/icons-29.png', open:false, options:[], selected:false },
           { route: '/admin/hospedaje', title:'Hospedaje', icon:'assets/img/menu/hospedaje.png', open:false, options:[], selected:false },
           // { route: '/admin/places-visited', title:'Lugares visitados', icon:'assets/img/menu/icons-30.png', open:false, options:[], selected:false },
           // { route: '/admin/new-places', title:'Nuevos lugares', icon:'assets/img/menu/icons-31.png', open:false, options:[], selected:false },
           { route: '/admin/events', title:'Eventos', icon:'assets/img/menu/icons-32.png', open:false, options:[], selected:false },
           { route: '/admin/company/profile/'+companyUuid, title:'Configuración', icon:'assets/img/menu/icono-setting.png', open:false, options:[], selected:false },
         ];
         break;
       }
       default:{
         // no hacemos nada
         array = []
         break;
       }
     }
     return array;
   }

}
