import { Component, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'session-alert',
  templateUrl: './session-alert.component.html',
  styleUrls: ['./session-alert.component.css']
})
export class SessionAlertComponent implements OnInit {
  metadata = {
    message:'',
    alert:false,
    showAlertSession:false,
    showlightboxAlert:false,
  }
  constructor(private session:SessionService, public router:Router) { }

  ngOnInit() {
    this.session.showAlert.subscribe((value)=>{
      this.metadata = value;
    })
  }


  /** función para reanudar la sesión
  */
  rechargeSesion(){
    let result = {
      main:{
        username:'',
        password:''
      }
    };
    result.main.username = this.session.getSession().user.main.username;
    result.main.password = this.session.getValidate();
    this.closeModal();
    this.session.refreshSession(result).then((data:any)=> {
      if (data.transaction === 'ok') {
        this.presentAler('sesión iniciada correctamente...');
        //recargamos el vigilante
      } else {
        this.presentAler('Usuario ó Contraseña incorrectos');
        console.log('Usuario o Contraseña incorrectos');
        this.endSession();
      }
    }).catch((error)=>{
      this.presentAler('Usuario ó Contraseña incorrectos');
      console.log('Error: ', error);
      this.endSession();
    });
  }

  /** función para cerrar sesión
  */
  endSession(){
    this.metadata.message = '';
    this.metadata.showAlertSession = false;
    this.metadata.showlightboxAlert = false;
    this.session.logout();
    this.router.navigate(['/']);
  }

  /** función para cerrar el modal
  */
  closeModal(){
    this.metadata.showlightboxAlert = false;
    this.metadata.showAlertSession = false;
    this.metadata.alert = false;
    this.metadata.message = '';
  }

  /** función para mostrar la alerta con un mensaje
  */
  presentAler(message){
    this.metadata.message = message;
    this.metadata.alert = true;
    this.metadata.showlightboxAlert = true;
  }

}
