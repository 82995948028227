<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Agregar imagenes</h3>
          </div>
          <div class="col-3">
              <button type="button" class="btn btn-danger btn-block" routerLink="/admin/tours">Regresar</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-left">
          <div class="row">
            <div class="col-6">
              <p>Tour: <strong>{{object.main.name}}</strong> </p>
              <p>Estado: <strong>{{object.system.state.main.name}}</strong> </p>
            </div>
            <div class="col-12">
              <keysoft-upload [uploadCode]="'tourImage:upload'" [downloadCode]="'tourImage:download'" [listFilesCode]="'tourImage:findAllByTour'" [deleteCode]="'tourImage:delete'" [objectUuid]="object.main.uuid" [editable]="true" [oneFileUploated]="false"></keysoft-upload>
            </div>
          </div>
        </mat-card>
      </div>
      <hr class="col-12">
      <div class="col-10">
        <p>Ingresa la url de un video.</p>
        <div class="row">
          <mat-form-field class="col-9" appearance="outline">
            <mat-label>&nbsp;URL</mat-label>
            <input matInput placeholder="URL" name="mUrl" [(ngModel)]="metadata.tourVideo.main.url"
            [formControl]="complexForm.controls['url']">
            <mat-error *ngIf="complexForm.controls['url'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-form-field>
          <div class="col-3">
            <button mat-raised-button color="primary" style="width:100%;" [disabled]="complexForm.invalid" (click)="addVideo();">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>URL</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let object of videoList;">
              <td>
                {{object.main.url}}
              </td>
              <td>
                <button class="mr-2" color="primary" mat-icon-button matTooltip="Quitar" (click)="deleteAVideo(object);">
                  <i class="material-icons">delete</i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
