import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  profileModel = {
    main:{
      uuid:'',
      paypalApiKey:'',
      mexPagoApiSecret:'',
      mexPagoApiKey:'',
      paypalApiSecret:''
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    }
  };

  object = JSON.parse(JSON.stringify(this.profileModel));

  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public snackBar: MatSnackBar, public loading:LoadingService, public fb: FormBuilder, public dialog: MatDialog ) {
    this.object.system.company.main.uuid =  this.session.getSessionObject().main.uuid;
   }

   ngOnInit(): void {
     this.activatedRoute.params.subscribe((params: Params)=>{
       this.object.system.company.main.uuid = params['uuid'];
       this.loading.show(true,"Espere un momento...");
       this.session.getRequest("profile:findByCompany",this.object).subscribe((data:any)=>{
         if(data.object){
           this.object = data.object;
         }
         this.loading.hide();
       },error=>{
         this.loading.hide();
         console.log(error);
       });
     },error=>{
       console.log("activatedRoute:error",error);
     })
   }

  send(){
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("profile:update",this.object).subscribe((data:any)=>{
      this.snackBar.open('Datos almacenados correctamente...', 'Aceptar', {duration: 5000});
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log("error:profile:update",error);
    })
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
