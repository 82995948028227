<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Listado de Paquetes</h3>
          </div>
          <div class="col-3">
              <button type="button" class="btn btn-danger btn-block" routerLink="/admin/package/update/new">Agregar Paquete</button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-center">
          <mat-card-content>
            <div class="row">
              <div class="col-12">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">NOMBRE</th>
                      <th scope="col">PRECIO / PERS</th>
                      <th scope="col">¿Incluye Hospedaje?</th>
                      <th scope="col">¿Incluye Vuelo?</th>
                      <th scope="col">ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let object of packageList">
                      <th>
                        {{object.main.name}}
                      </th>
                      <th><span class="text-info">{{object.main.pricePerson | currency:'CAD':'symbol-narrow'}}</span></th>
                      <th>{{object.main.lodging ? 'SI':'NO'}}</th>
                      <th>{{object.main.flight ? 'SI':'NO'}}</th>
                      <td>
                        <button class="mr-2" color="primary" mat-icon-button matTooltip="Editar" routerLink="/admin/package/update/{{object.main.uuid}}">
                          <i class="material-icons">mode_edit</i>
                        </button>

                        <!--<span class="material-icons" style="color:#0071bb; cursor:pointer;" routerLink="/admin/package/addItinerario/{{object.main.uuid}}" matTooltip="Subir itinerario">view_list</span>-->
                        <button class="mr-2" color="primary" mat-icon-button matTooltip="Subir itinerario" routerLink="/admin/package/addItinerario/{{object.main.uuid}}">
                          <i class="material-icons">event</i>
                        </button>
                        <button class="mr-2" color="primary" mat-icon-button matTooltip="Subir imagen" routerLink="/admin/package/addImage/{{object.main.uuid}}">
                          <i class="material-icons">perm_media</i>
                        </button>
                        <button class="mr-2" mat-icon-button matTooltip="Eliminar">
                          <span class="material-icons" style="color:#f05a24; cursor:pointer;" (click)="delete(object)" matTooltip="Eliminar">delete_outline</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
              <div class="col-6 ">
                <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
                </mat-paginator>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
