import { environment } from '../../../environments/environment';
/**url / endpoint para la conexión al backend
* *Si environment.production es true devuelve url de "url_de_producción"
* *Si environment.production es false devuelve url de "url_de_desarrollo"
*
*/
const server_url:string = environment.production ? 'https://turismo.keysoft.mx/TURI/':'https://turismo.keysoft.mx/TURI/';
// const server_url:string = environment.production ? 'https://turismo.keysoft.mx/TURI/':'http://localhost:9095/Turi-Web-0811-Backend/';
// const server_url:string = environment.production ? 'http://25.17.175.137:9095/Turi-Web-0811-Backend/':'http://25.17.175.137:9095/Turi-Web-0811-Backend/';


export default server_url;
