
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code: "api:login",
    endpoint: "api/login"
  }, {
    code: "session:create",
    endpoint: "validate/validate"
  },  {
    code: "state:list",
    endpoint: "state/list"
  },{
    code: "companyState:findAllByCompany",
    endpoint: "companyState/findAllByCompany"
  },{
    code: "tour:update",
    endpoint: "tour/update"
  },{
    code: "tour:findAllByCompany",
    endpoint: "tour/findAllByCompany"
  },{
    code: "tour:get",
    endpoint: "tour/get"
  },{
    code: "tour:delete",
    endpoint: "tour/delete"
  },{
    code: "placeDeparture:delete",
    endpoint: "placeDeparture/delete"
  },{
    code: "tourImage:delete",
    endpoint: "tourImage/delete"
  },{
    code: "tourImage:findAllByTour",
    endpoint: "tourImage/findAllByTour"
  },{
    code: "tourImage:getImage",
    endpoint: "tourImage/getImage"
  },{
    code: "tourImage:upload",
    endpoint: "tourImage/upload"
  },{
    code: "tourImage:upload",
    endpoint: "tourImage/upload"
  },{
    code: "tourImage:download",
    endpoint: "tourImage/download"
  },{
    code: "package:delete",
    endpoint: "package/delete"
  },{
    code: "package:findAllByCompany",
    endpoint: "package/findAllByCompany"
  },{
    code: "package:update",
    endpoint: "package/update"
  },{
    code: "package:get",
    endpoint: "package/get"
  },{
    code: "packageImage:upload",
    endpoint: "packageImage/upload"
  },{
    code: "packageImage:download",
    endpoint: "packageImage/download"
  },{
    code: "packageImage:findAllBypackage",
    endpoint: "packageImage/findAllByPackage"
  },{
    code: "packageImage:delete",
    endpoint: "packageImage/delete"
  },{
    code: "packageVideo:findAllByPackage",
    endpoint: "packageVideo/findAllByPackage"
  },{
    code: "packageVideo:update",
    endpoint: "packageVideo/update"
  },{
    code: "packageVideo:delete",
    endpoint: "packageVideo/delete"
  },{
    code: "event:update",
    endpoint: "event/update"
  },{
    code: "event:delete",
    endpoint: "event/delete"
  },{
    code: "event:findAllByCompany",
    endpoint: "event/findAllByCompany"
  },{
    code: "event:get",
    endpoint: "event/get"
  },{
    code: "sale:findAllByCompanyAndStatus",
    endpoint: "sale/findAllByCompanyAndStatus"
  },{
    code: "sale:get",
    endpoint: "sale/get"
  },{
    code: "profile:findByCompany",
    endpoint: "profile/findByCompany"
  },{
    code: "contactEvent:findAllByCompany",
    endpoint: "contactEvent/findAllByCompany"
  },{
    code: "eventImage:upload",
    endpoint: "eventImage/upload"
  },{
    code: "eventImage:findAllByEvent",
    endpoint: "eventImage/findAllByEvent"
  },{
    code: "eventImage:download",
    endpoint: "eventImage/download"
  },{
    code: "eventImage:delete",
    endpoint: "eventImage/delete"
  },{
    code: "packetDay:update",
    endpoint: "packetDay/update"
  },{
    code: "packetDay:get",
    endpoint: "packetDay/get"
  },{
    code: "packetDay:findAllByPackage",
    endpoint: "packetDay/findAllByPackage"
  },{
    code: "dateTypeAccommodation:findAllByCompany",
    endpoint: "dateTypeAccommodation/findAllByCompany"
  },{
    code: "dateTypeAccommodation:get",
    endpoint: "dateTypeAccommodation/get"
  },{
    code: "dateTypeAccommodation:update",
    endpoint: "dateTypeAccommodation/update"
  },{
    code: "typeAccommodation:update",
    endpoint: "typeAccommodation/update"
  },{
    code: "typeAccommodation:findAllByCompany",
    endpoint: "typeAccommodation/findAllByCompany"
  },{
    code: "profile:update",
    endpoint: "profile/update"
  },{
    code: "packetDay:delete",
    endpoint: "packetDay/delete"
  },{
    code: "tourVideo:findAllByTour",
    endpoint: "tourVideo/findAllByTour"
  },{
    code: "tourVideo:update",
    endpoint: "tourVideo/update"
  },{
    code: "tourVideo:delete",
    endpoint: "tourVideo/delete"
  }
];

export default urls;
