<mat-drawer-container class="drawerConteiner">
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Nuevo Paquete</h3>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-danger btn-block" routerLink="/admin/package">Regresar</button>
          </div>
        </div>
      </div>

      <div class="col-10">
        <mat-card class="text-center">
          <div class="row mt-4 mb-3 justify-content-center">
            <div class="col-10">
              <div class="borderbox">
                <p class="titlebox">Datos generales</p>
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>Estado</mat-label>
                  <mat-select required name="cMaritalStatus" class="full-width-select"
                    [(ngModel)]="object.system.state.main.uuid">
                    <mat-option *ngFor="let object of stateList" [value]="object.system.state.main.uuid">
                      {{object.system.state.main.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Minimo de personas</mat-label>
                  <input matInput placeholder="Nombre de Tour" name="mMinimun" type="number"
                    [(ngModel)]="object.main.minimumPerson">
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="col-10">
        <mat-card class="text-center">
          <div class="row mt-4 mb-3">
            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Datos en español</p>
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Nombre del Paquete</mat-label>
                  <input matInput placeholder="Nombre del paquete" name="namepes"
                    [formControl]="complexForm.controls['namepes']" [(ngModel)]="object.main.name">
                  <mat-error *ngIf="complexForm.controls['namepes'].hasError('required')">Campo requerido</mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Incluye</mat-label>
                  <textarea matInput placeholder="Descripción" name="descripincludes" rows="4" cols="80"
                    [formControl]="complexForm.controls['descripincludes']"
                    [(ngModel)]="object.complement.description"></textarea>
                  <mat-error *ngIf="complexForm.controls['descripincludes'].hasError('required')">Campo requerido
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;No incluye</mat-label>
                  <textarea matInput placeholder="Descripción" name="descripnotincludes" rows="4" cols="80"
                    [formControl]="complexForm.controls['descripnotincludes']"
                    [(ngModel)]="object.complement.notDescription"></textarea>
                  <mat-error *ngIf="complexForm.controls['descripnotincludes'].hasError('required')">Campo requerido
                  </mat-error>
                </mat-form-field>

                <p class="titlebox">Datos en inglés</p>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Nombre del Paquete en Inglés</mat-label>
                  <input matInput placeholder="Nombre del Paquete en Inglés" name="nameEn"
                    [(ngModel)]="object.main.nameEn">
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Incluye en Inglés</mat-label>
                  <textarea matInput placeholder="Descripción en Inglés" name="descriptionEn" rows="4" cols="80"
                    [(ngModel)]="object.complement.descriptionEn"></textarea>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;No incluye en Inglés</mat-label>
                  <textarea matInput placeholder="Descripción en Inglés" name="notDescriptionEn" rows="4" cols="80"
                    [(ngModel)]="object.complement.notDescriptionEn"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Precios (MXN)</p>

                <mat-form-field class="col-12 col-md-6" appearance="outline">
                  <mat-label>&nbsp;Apartado por persona</mat-label>
                  <input matInput placeholder="Apartador minimo" name="secludedPerson" type="number"
                    [formControl]="complexForm.controls['secludedPerson']" [(ngModel)]="object.main.secludedPerson">
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="complexForm.controls['secludedPerson'].hasError('required')">Campo requerido
                  </mat-error>
                  <mat-hint align="start"><strong>Ingrese el precio en MXN</strong></mat-hint>
                </mat-form-field>

                <mat-form-field class="col-12 col-md-6" appearance="outline">
                  <mat-label>&nbsp;Precio por persona</mat-label>
                  <input matInput placeholder="Precio por persona" name="pricePerson" type="number"
                    [formControl]="complexForm.controls['pricePerson']" [(ngModel)]="object.main.pricePerson">
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="complexForm.controls['pricePerson'].hasError('required')">Campo requerido
                  </mat-error>
                  <mat-hint align="start"><strong>Ingrese el precio en MXN</strong></mat-hint>
                </mat-form-field>

                <p class="titlebox">Precios (USD)</p>

                <mat-form-field class="col-12 col-md-6" appearance="outline">
                  <mat-label>&nbsp;Apartado por persona</mat-label>
                  <input matInput placeholder="Apartador minimo" name="secludedPerson" type="number"
                    [(ngModel)]="object.main.secludedPersonEn">
                  <span matPrefix>$&nbsp;</span>
                  <mat-hint align="start"><strong>Ingrese el precio en USD</strong></mat-hint>

                </mat-form-field>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                  <mat-label>&nbsp;Precio por persona</mat-label>
                  <input matInput placeholder="Precio por persona" name="pricePerson" type="number"
                    [(ngModel)]="object.main.pricePersonEn">
                  <span matPrefix>$&nbsp;</span>
                  <mat-hint align="start"><strong>Ingrese el precio en USD</strong></mat-hint>
                </mat-form-field><br>
             </div>
                <div class="borderboxx">
                  <p class="titlebox">Datos de contacto</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>&nbsp;Teléfono</mat-label>
                    <input type="text" maxlength="10" onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;" matInput
                    placeholder="Teléfono"  name="contactNumber"
                      [formControl]="complexForm.controls['contactNumber']" [(ngModel)]="object.complement.contactNumber">
                    <mat-error *ngIf="complexForm.controls['contactNumber'].hasError('required')">Campo requerido
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>&nbsp;Email</mat-label>
                    <input matInput placeholder="Email" type="email" name="contactEmail"
                      [formControl]="complexForm.controls['contactEmail']" [(ngModel)]="object.complement.contactEmail">
                    <mat-error *ngIf="complexForm.controls['contactEmail'].hasError('required')">Campo requerido
                    </mat-error>
                  </mat-form-field>

                </div>
                <div class="borderboox">
                  <p class="titlebox">Otros datos</p>
                  <div class="col-md-6" style="text-align: left; padding-bottom: 18px;margin-left: 5px;">
                    <p>¿ Incluye Hospedaje ? </p>
                    <mat-slide-toggle name="mChildren" [(ngModel)]="object.main.lodging"> <span
                        *ngIf="!object.main.lodging"> <strong>No</strong> </span> <span *ngIf="object.main.lodging">
                        <strong>Si</strong> </span> </mat-slide-toggle><br>

                        <p>¿ Incluye Vuelo ? </p>
                    <mat-slide-toggle name="mChildren" [(ngModel)]="object.main.flight"> <span
                        *ngIf="!object.main.flight"> <strong>No</strong> </span> <span *ngIf="object.main.flight">
                        <strong>Si</strong> </span> </mat-slide-toggle>
                  </div>

                </div>

            </div>
          </div><br>

          <div class="row justify-content-end mt-8">
            <div class="col-4">
              <button type="button" class="btn btn-danger btn-block" (click)="send()">Guardar</button>
            </div>
          </div>


        </mat-card>
      </div>

    </div>
  </mat-drawer-content>
</mat-drawer-container>
