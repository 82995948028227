import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../service/session/session.service';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})
export class ToolBarComponent implements OnInit {
  metadata = {
    toggleMenu: false,
  }
  @Output() menuToogle: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private router: Router, public session:SessionService,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  salir(){
    this.session.logout().then(()=>{
      this.router.navigate(['/']);
      this.snackBar.open('Nos vemos pronto...', 'Error', {duration: 5000});

    }).catch(e=>{
      this.router.navigate(['/']);
    })
  }
  /*
  * funcion para abrir o cerrar le menu
  */
  toogleMenu(){
    this.metadata.toggleMenu = !this.metadata.toggleMenu;
    this.menuToogle.emit(this.metadata.toggleMenu);
  }
}
