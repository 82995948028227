<div class="row justify-content-center fondo-login" style="margin-left:0px; margin-right:0px;">
  <div class="col-3">
    <img class="pt-1 pb-1"src="/assets/img/logo.png" style="width: 10rem;">
  </div>
  <div class="col-4 mt-5 pt-5">
    <mat-card class="mt-5 ">
      <form class="row login-form mt-5 justify-content-center">
         <mat-form-field class="col-10 mt-3 mb-2">
           <mat-label>&nbsp;Usuario</mat-label>
           <input matInput placeholder="Usuario" name="mName"  [formControl]="complexForm.controls['username']" [(ngModel)]="object.main.username">
           <span matPrefix class="material-icons">face</span>
           <mat-error *ngIf="complexForm.controls['username'].hasError('required')">Campo requerido</mat-error>
         </mat-form-field>
         <mat-form-field class="col-10 mt-1 mb-2">
           <mat-label>&nbsp;Contraseña</mat-label>
           <input matInput type="password" placeholder="Contraseña" name="mPassword" [(ngModel)]="object.main.password" [formControl]="complexForm.controls['password']" (keydown.Enter) ="send(object);">
           <span matPrefix class="material-icons">lock</span>
           <mat-error *ngIf="complexForm.controls['password'].hasError('required')">Campo requerido</mat-error>
         </mat-form-field>
         <br />
         <div class="col-10 text-right mt-1 mb-2">
            <button mat-button>Olvide mi contraseña</button>
         </div>
     </form>
     <mat-card-actions class="mt-3 text-center">
       <button mat-raised-button color="warn" class="w-50" (click)="send(object);" [disabled]="complexForm.invalid">INGRESAR</button>
     </mat-card-actions>
    </mat-card>
  </div>
  <div class="col-3"></div>
</div>
