import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import {MatDialog} from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-admin-package-update',
  templateUrl: './admin-package-update.component.html',
  styleUrls: ['./admin-package-update.component.css']
})
export class AdminPackageUpdateComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;


  Package = {
    main: {
      uuid: '',
      // Nombre del paquete en español
      name: '',
  // Nombre del paquete en Ingles
      nameEn: '',
  // Hospedaje, true incluye hospedaje, false en caso contrario
      lodging: true,
      flight: true,
  // Minimo de personas
      minimumPerson: 1,
  // Precio por persona
      pricePerson: 0.0,
  // Precio por persona en dolares
      pricePersonEn: 0.0,
  // Costo de apartado por persona
      secludedPerson: 0.0,
  // Costo de apartado por persona en dolares
      secludedPersonEn: 0.0,
  },
    complement: {
  // Descripción de lo que incluye el paquete
      description: '',
  // Descripción de lo que incluye el paquete en Ingles
      descriptionEn: '',
  // Descripción de lo que no incluye el paquete
      notDescription: '',
  // Descripción de lo que no incluye el paquete en Ingles
      notDescriptionEn: '',
  // Numero de contacto para mas informes
      contactNumber: '',
  // Correo para solicitar mas informes
      contactEmail: ''
  },
    system: {
      company: {
        main: {
          uuid: ''
        }
    },
    state: {
      main: {
        uuid: ''
      }
    }
  }
}

object = JSON.parse(JSON.stringify(this.Package));

stateList = [];
packageTourList = [];

metadata = {
  generalDataEnglish:false,
  priceData:false
}


/** Días que integran el itinerario del paquete */
PacketDayModel = {
	main: {
    uuid: '',
// Número de día: día uno, día dos, etc.
    numberDay: '',
// Descripción del recorrido que tendrán en el día
    description: '',
},
system: {
// Paquete al que pertenece el día
  package: {
	  main: {
		  uuid: ''
  			}
    }
  }
}


  /*-------------------------------------------------------------------------------*/
  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public snackBar: MatSnackBar, public loading:LoadingService, public fb: FormBuilder ) {
    
    this.stateList = this.session.getStateList();
    this.complexForm = fb.group({
      'namepes': [null, Validators.compose([Validators.required])],
      'descripincludes': [null, Validators.compose([Validators.required])],
      'descripnotincludes': [null, Validators.compose([Validators.required])],
      'namepen': [null, Validators.compose([Validators.required])],
      'descriptincluden': [null, Validators.compose([Validators.required])],
      'descripnotincluden': [null, Validators.compose([Validators.required])],
      'contactNumber': [null, Validators.compose([Validators.required])],
      'contactEmail': [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      'secludedPerson': [null, Validators.compose([Validators.required])],
      'pricePerson': [null, Validators.compose([Validators.required])],
      'vigency': [null, Validators.compose([Validators.required])],      
    });
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params)=>{
      if(params['uuid'] == 'new'){
      }else{
        this.object.main.uuid = params['uuid'];
        this.loading.show(true,"Espere un momento...")
        this.session.getRequest("package:get",this.object).subscribe((data:any)=>{
          this.object = data.object;
          this.loading.hide();
        },error=>{
          console.log('package:get',error);
          this.loading.hide();
        })
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })

  }

  /**función para guardar un paquete
  */
  send(){
    this.object.system.state.main.uuid = this.session.getState().main.uuid;
    this.object.system.company.main.uuid =  this.session.getSessionObject().main.uuid;
    console.log(this.session.getSessionObject());
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("package:update",this.object).subscribe(async(data:any)=>{
      this.object.main.uuid = data.object.main.uuid;
      this.loading.hide();
      this.router.navigate(['/admin/package'])
    },error=>{
      console.log('package:update',error);
      this.loading.hide();
    })
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
