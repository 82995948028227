import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AdminToursComponent } from './admin/admin-tours/admin-tours.component';
import { AdminToursUpdateComponent } from './admin/admin-tours/admin-tours-update/admin-tours-update.component';
import { AdminPackageComponent } from './admin/admin-package/admin-package.component';
import { AdminPackageUpdateComponent } from './admin/admin-package/admin-package-update/admin-package-update.component';
import { AdminNewPlacesComponent } from './admin/admin-new-places/admin-new-places.component';
import { AdminNewPlacesUpdateComponent } from './admin/admin-new-places/admin-new-places-update/admin-new-places-update.component';
import { AdminPlacesVisitedComponent } from './admin/admin-places-visited/admin-places-visited.component';
import { AdminEventsComponent } from './admin/admin-events/admin-events.component';
import { AdminEventsUpdateComponent } from './admin/admin-events/admin-events-update/admin-events-update.component';
import { AdminSettingComponent } from './admin/admin-setting/admin-setting.component';
import { AdminSettingUpdateComponent } from './admin/admin-setting/admin-setting-update/admin-setting-update.component';
import { AdminToursAddImageComponent } from './admin/admin-tours/admin-tours-add-image/admin-tours-add-image.component';
import { AdminPackageAddImageComponent } from './admin/admin-package/admin-package-add-image/admin-package-add-image.component';
import { AdminSaleComponent } from './admin/admin-sale/admin-sale.component';
import { AdminSaleUpdateComponent } from './admin/admin-sale/admin-sale-update/admin-sale-update.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { AdminEventsAddImageComponent } from './admin/admin-events/admin-events-add-image/admin-events-add-image.component';
import { AdminPackageAddItinerarioComponent } from './admin/admin-package/admin-package-add-itinerario/admin-package-add-itinerario.component';
import { AdminHospedajeComponent } from './admin/admin-hospedaje/admin-hospedaje.component';
import { AdminPeriodoUpdateComponent } from './admin/admin-hospedaje/admin-periodo-update/admin-periodo-update.component';
import { AdminHospedajeAddHospedajeComponent } from './admin/admin-hospedaje/admin-hospedaje-add-hospedaje/admin-hospedaje-add-hospedaje.component';


const routes: Routes = [
  { path :'' ,redirectTo:'login' , pathMatch:'full' },
  { path:'login',component:LoginComponent },
  { path:'admin',component:AdminComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/sale',component:AdminSaleComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/sale/update/:uuid',component:AdminSaleUpdateComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/tours',component:AdminToursComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/tours/update/:uuid',component:AdminToursUpdateComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/tours/addImage/:uuid',component:AdminToursAddImageComponent , canActivate: [AuthenticationGuard] },
  { path:'admin/package',component:AdminPackageComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/package/update/:uuid',component:AdminPackageUpdateComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/package/addImage/:uuid',component:AdminPackageAddImageComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/package/addItinerario/:uuid',component:AdminPackageAddItinerarioComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/hospedaje',component:AdminHospedajeComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/hospedaje/addHospedaje/:uuid',component:AdminHospedajeAddHospedajeComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/periodo/update/:uuid',component:AdminPeriodoUpdateComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/events/addImage/:uuid',component:AdminEventsAddImageComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/places-visited',component:AdminPlacesVisitedComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/new-places',component:AdminNewPlacesComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/new-places/update',component:AdminNewPlacesUpdateComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/events',component:AdminEventsComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/events/update/:uuid',component:AdminEventsUpdateComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/setting',component:AdminSettingComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/setting/update',component:AdminSettingUpdateComponent, canActivate: [AuthenticationGuard] },
  { path:'admin/company/profile/:uuid',component:AdminProfileComponent , canActivate: [AuthenticationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
