import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-admin-new-places-update',
  templateUrl: './admin-new-places-update.component.html',
  styleUrls: ['./admin-new-places-update.component.css']
})
export class AdminNewPlacesUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  constructor() { }

  ngOnInit(): void {
  }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
