<!-- The Modal/Lightbox -->

<div id="lightboxAlert" class="lightboxAlert" [ngStyle]="{'display': metadata.showlightboxAlert ? 'block': 'none'}">
  <span class="close cursor" (click)="closeModal()" *ngIf="!metadata.showAlertSession">&times;</span>
  <div class="lightboxAlert-content">
    <div class="row justify-content-center p-3">
      <div class="col-10">
        <h3>Alerta de sesión</h3>
      </div>
      <div class="col-10">
        <!-- <p>La sesión <strong>expira</strong>  en <strong>10 min</strong>, por favor, pulse <strong>reanudar</strong> para volver a acceder a la aplicación.</p> <br> -->
        <p>{{metadata.message}}</p><br>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-5" *ngIf="metadata.showAlertSession">
        <button type="button" class="btn btn-material btn-sm" (click)="endSession();">Cerrar sesión</button>
      </div>
      <div class="col-5" *ngIf="metadata.showAlertSession">
        <button type="button" class="btn btn-material-accent btn-sm" (click)="rechargeSesion();">Reanudar sesión</button>
      </div>
      <div class="col-5">
        <button type="button" class="btn btn-material-accent btn-sm" *ngIf="metadata.alert" (click)="closeModal();">Aceptar</button>
      </div>
    </div>
  </div>
</div>
