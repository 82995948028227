<mat-drawer-container class="drawerConteiner">
    <mat-drawer #drawer mode="side" class="mainDrawer">
      <app-admin-menu></app-admin-menu>
    </mat-drawer>
    <mat-drawer-content class="drawerContent">
      <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
      <div class="row justify-content-center">
        <div class="col-10 text-right">
          <div class="row mt-2 mb-2 justify-content-end">
            <div class="col-9 text-left">
              <h3 class="text-uppercase pt-2 pb-2">Temporada de hospedaje</h3>
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-danger btn-block" routerLink="/admin/hospedaje">Regresar</button>
            </div>
          </div>
        </div>
        <div class="col-8">
          <mat-card class="text-center">
            <div class="row mt-4 mb-3">
              <div class="col-11 row justify-content-center">
                <p class="titlebox col-12">Periodo por temporada</p>

                <div class="col-8" style="text-align: left; padding-bottom: 18px;margin-left: 5px;">
                  <p>Tipo de Temporada </p>
                  <mat-slide-toggle name="mChildren" [(ngModel)]="object.main.seasonType"> <span
                      *ngIf="object.main.seasonType"> <strong>alta</strong> </span> <span *ngIf="!object.main.seasonType">
                      <strong>baja</strong> </span> </mat-slide-toggle>
                </div>

                <mat-form-field appearance="outline" class="col-4">
                  <mat-label>Fecha de inicio del periodo</mat-label>
                  <input matInput [min]="minDate" [matDatepicker]="picker1" name="startPeriod"
                    [formControl]="complexForm.controls['startPeriod']" [(ngModel)]="metadata.startPeriod">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                  <mat-error *ngIf="complexForm.controls['startPeriod'].hasError('required')">
                    Campo <strong>requerido</strong>.
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-4">
                  <mat-label>Fecha de final del periodo</mat-label>
                  <input matInput [min]="minDate" [matDatepicker]="picker2" name="endPeriod"
                    [formControl]="complexForm.controls['endPeriod']" [(ngModel)]="metadata.endPeriod">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error *ngIf="complexForm.controls['endPeriod'].hasError('required')">
                    Campo <strong>requerido</strong>.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-end mt-4">
              <div class="col-12 col-md-4">
                <button type="button" class="btn btn-danger btn-block" (click)="send()">Guardar</button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
