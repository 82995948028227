import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-admin-package',
  templateUrl: './admin-package.component.html',
  styleUrls: ['./admin-package.component.css']
})
export class AdminPackageComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  packageMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  packageList = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(public loadding:LoadingService, protected session: SessionService, public snackBar: MatSnackBar) {
    this.packageMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.packageList = [];
        this.packageMin.filter.name = '';
      }else{
        this.packageList = [];
        this.packageMin.filter.name = '';
        this.packageMin.filter.value = '';
      }
      this.getPackageList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }


  ngOnInit(): void {
    this.getPackageList();
  }
  /**funcionalidad para obetner el listado de tours
  **/
  getPackageList(){
    this.packageList = [];
    this.loadding.wait();
    this.session.getRequest("package:findAllByCompany",this.packageMin).subscribe(
      (data:any) => {
        this.packageList = data.object.instanceList;
        this.paginator.total = data.object.total;
        this.loadding.hide();
     },
     error => {
       console.log("package:findAllByCompany",error);
       this.loadding.hide();
     }
    );
  }

  delete(object) {
    // Objecto a Eliminar
    this.loadding.show(true,"Espera un momento...");
    this.session.getRequest("package:delete", object).subscribe((data:any) => {
        for (let x=0; x<this.packageList.length; x++) {
          if (object.main.uuid == this.packageList[x].main.uuid) {
            this.packageList.splice(x, 1);
          }
        }
        this.snackBar.open('Se elimino correctamente el paquete seleccionado', 'Información', {duration: 5000});
        this.loadding.hide();
     }, error => {
       console.log("tour:list",error);
       this.loadding.hide();
     });
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.packageMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.packageMin.offset = (event.pageIndex * event.pageSize);
    this.packageMin.max = event.pageSize;
    this.getPackageList();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
