<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-12 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Listado de Ventas</h3>
          </div>
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-center">
          <mat-card-content>
            <div class="row">
              <div class="col-12">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">FOLIO</th>
                      <th scope="col">CLIENTE</th>
                      <th scope="col">TIPO DE COMPRA</th>
                      <th scope="col">FECHA COMPROMISO</th>
                      <th scope="col">FECHA DE COMPRA</th>
                      <th scope="col">MONTO ACREDITADO</th>
                      <th scope="col">ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let object of saleList">
                      <th scope="row">{{object.main.saleNumber}}</th>
                      <th scope="row">{{object.complement.nameClient}}</th>
                      <th>{{ object.system.saleDetail.main.advance ? 'Anticipo' : 'Completo' }}</th>
                      <th>{{ object.system.saleDetail.main.date | date: 'dd-MM-yyyy HH:mm:ss'}}</th>
                      <th>{{ object.main.expeditionDate | date: 'dd-MM-yyyy HH:mm:ss'}}</th>
                      <th>{{ object.main.total | currency:'CAD':'symbol-narrow'}}</th>
                      <td>
                        <button class="mr-2" color="primary" mat-icon-button matTooltip="Editar" routerLink="/admin/tours/update/{{object.main.uuid}}">
                          <i class="material-icons">view</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
              <div class="col-6 ">
                <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
                </mat-paginator>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
