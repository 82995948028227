<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center mt-2">
      <div class="col-10">
        <mat-card>
          <div class="row justify-content-center mt-4">
            <div class="col-12 col-md-8">
              <h2>Perfil</h2>
              <p>Para hacer uso de cobro por medio de app "México Travel Click" agregue las llaves de conexion a <strong>Paypal</strong> y <strong>Mexpago</strong></p>
              <mat-form-field class="col-12 pb-3" appearance="outline">
                <mat-label>Api Key Paypal</mat-label>
                <input matInput placeholder="Api Key Paypal" name="mApiKey"[(ngModel)]="object.main.paypalApiKey">
                <mat-hint>Agregar el Client ID de <strong>Paypal</strong> </mat-hint>
              </mat-form-field>

              <mat-form-field class="col-12 pb-3" appearance="outline">
                <mat-label>Api Secret Paypal</mat-label>
                <input matInput placeholder="Api Key Paypal" name="mApiKey"[(ngModel)]="object.main.paypalApiSecret">
                <mat-hint>Agregar la llave secreta de <strong>Paypal</strong> </mat-hint>
              </mat-form-field>

              <mat-form-field class="col-12 pb-3" appearance="outline">
                <mat-label>Api Key Mexpago</mat-label>
                <input matInput placeholder="Api Key Mexpago" name="mApiKeyMExpago"[(ngModel)]="object.main.mexPagoApiKey">
                <mat-hint>Agregar la llave publica de  <strong>Mexpago</strong>  </mat-hint>
              </mat-form-field>

              <mat-form-field class="col-12 pb-3" appearance="outline">
                <mat-label>Api Secret Mexpago</mat-label>
                <input matInput placeholder="Api Secret Mexpago" name="mApiSecretMexpago"[(ngModel)]="object.main.mexPagoApiSecret">
                <mat-hint>Agregar la llave secreta de  <strong>Mexpago</strong>  </mat-hint>
              </mat-form-field>
              <div class="col-12">
                <br><br>
                <button type="button" class="btn btn-danger btn-block" (click)="send();">Guardar</button>
              </div>
            </div>

          </div>

        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
