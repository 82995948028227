import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as Rx from 'rxjs/Rx';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-package-add-itinerario',
  templateUrl: './admin-package-add-itinerario.component.html',
  styleUrls: ['./admin-package-add-itinerario.component.css']
})
export class AdminPackageAddItinerarioComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  Package = {
    main: {
      uuid: '',
      // Nombre del paquete en español
      name: '',
      // Nombre del paquete en Ingles
      nameEn: '',
      // Hospedaje, true incluye hospedaje, false en caso contrario
      lodging: true,
      // Minimo de personas
      minimumPerson: 1,
      // Precio por persona
      pricePerson: 0.0,
      // Precio por persona en dolares
      pricePersonEn: 0.0,
      // Costo de apartado por persona
      secludedPerson: 0.0,
      // Costo de apartado por persona en dolares
      secludedPersonEn: 0.0,
    },
    complement: {
      // Descripción de lo que incluye el paquete
      description: '',
      // Descripción de lo que incluye el paquete en Ingles
      descriptionEn: '',
      // Descripción de lo que no incluye el paquete
      notDescription: '',
      // Descripción de lo que no incluye el paquete en Ingles
      notDescriptionEn: '',
      // Numero de contacto para mas informes
      contactNumber: '',
      // Correo para solicitar mas informes
      contactEmail: ''
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      state: {
        main: {
          uuid: ''
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.Package));

  /** Días que integran el itinerario del paquete */
  packetDayModel = {
    main: {
      uuid: '',
      // Número de día: día uno, día dos, etc.
      numberDay: '',
      // Descripción del recorrido que tendrán en el día
      description: '',
    },
    system: {
      // Paquete al que pertenece el día
      package: {
        main: {
          uuid: ''
        }
      }
    }
  }

  objectPacket = JSON.parse(JSON.stringify(this.packetDayModel));

  packetDayMin = {
    system: {
      package: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }

  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    }
  }
  paginator = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };

  dayList = [];
  itinerario = [];

  pageEvent: PageEvent;
  constructor(private session: SessionService, private router: Router, public activatedRoute: ActivatedRoute, public loading: LoadingService, public snackBar: MatSnackBar, public fb: FormBuilder) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.dayList = [];
          this.packetDayMin.filter.name = '';
        } else {
          this.dayList = [];
          this.packetDayMin.filter.name = '';
          this.packetDayMin.filter.value = '';
        }
        this.getPackageDayList();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
    this.complexForm = fb.group({
      'mNumberDay': [null, Validators.compose([Validators.required])],
      'mDescription': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    console.log('hola', this.itinerario);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.object.main.uuid = params['uuid'];
      this.loading.show(true, "Espere un momento...")
      this.session.getRequest("package:get", this.object).subscribe((data: any) => {
        this.object = data.object;
        this.packetDayMin.system.package.main.uuid = this.object.main.uuid;
        this.itinerario = [];
        this.getPackageDayList();
        this.loading.hide();
      }, error => {
        console.log('packetDay:get', error);
        this.loading.hide();
      })
    }, error => {
      console.log("activatedRoute:error", error);
    })
  }

  send() {
    this.objectPacket.system.package.main.uuid = this.object.main.uuid;
    this.loading.show(true, "Espere un momento...");
    console.log(this.object);
    this.session.getRequest("packetDay:update", this.objectPacket).subscribe(async (data: any) => {
      this.objectPacket = JSON.parse(JSON.stringify(this.packetDayModel));
      this.itinerario = [];
      this.getPackageDayList();
      this.complexForm.reset();
      this.loading.hide();
    }, error => {
      console.log('packetDay:update', error);
      this.loading.hide();
    })
  }


  /* funcion para obtener el listado de itinerario
    */
  getPackageDayList() {
    this.dayList = [];
    this.loading.wait();
    this.session.getRequestAnonymous("packetDay:findAllByPackage", this.packetDayMin).subscribe(
      (data: any) => {
        this.itinerario = data.object.instanceList;
        this.paginator.total = data.object.total;
        this.loading.hide();
      },
      error => {
        console.log("packetDay:findAllByPackage", error);
        this.loading.hide();
      }
    );
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.packetDayMin.offset = (event.pageIndex * event.pageSize);
    this.packetDayMin.max = event.pageSize;
    this.getPackageDayList();
  }

  /**
  funcionalidad para editar un itinerarioº
  */
  editIten(object){
    this.objectPacket = JSON.parse(JSON.stringify(object));
  }

  /**funcionalidad para eliminar un itenerario
  */
  delete(object){
    this.loading.show(true, "Espere un momento...")
    this.session.getRequest("packetDay:delete", object).subscribe((data: any) => {
      for(let i = 0; i < this.itinerario.length; i++){
        if(this.itinerario[i].main.uuid == object.main.uuid){
          this.itinerario.splice(i,1);
        }
      }
      this.loading.hide();
    }, error => {
      console.log('packetDay:delete', error);
      this.snackBar.open("No se puede eliminar el itinerario.","",{duration:3000})
      this.loading.hide();
    })
  }


  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
