<mat-drawer-container class="drawerConteiner">
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-9 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Nuevo Evento</h3>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-danger btn-block" routerLink="/admin/events">Regresar</button>
          </div>
        </div>
      </div>

      <div class="col-10">
        <mat-card class="text-center">
          <div class="row mt-4 mb-3 justify-content-center">
            <div class="col-10">
              <div class="borderbox">
                <p class="titlebox">Datos generales</p>
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>Estado</mat-label>
                  <mat-select required name="cMaritalStatus" class="full-width-select"
                    [(ngModel)]="object.system.state.main.uuid">
                    <mat-option *ngFor="let object of stateList" [value]="object.system.state.main.uuid">
                      {{object.system.state.main.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Vigencia</mat-label>
                  <input matInput [min]="minDate" [matDatepicker]="picker" name="vigency"
                    [formControl]="complexForm.controls['vigency']" [(ngModel)]="metadata.apartDate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="complexForm.controls['vigency'].hasError('required')">
                    Campo <strong>requerido</strong>.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Numero de personas</mat-label>
                  <input matInput placeholder="Numero de personas" name="NumberPerson" type="number"
                    [formControl]="complexForm.controls['numberPerson']" [(ngModel)]="object.main.numberPeople">
                  <mat-error *ngIf="complexForm.controls['numberPerson'].hasError('required')">Campo requerido
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-card>

      </div>

      <div class="col-10">
        <mat-card class="text-center">          
          <div class="row mt-4 mb-3">
            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Datos en español</p>
                
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Nombre del Evento</mat-label>
                  <input matInput placeholder="Nombre del Evento" name="mName"
                    [formControl]="complexForm.controls['name']" [(ngModel)]="object.main.name">
                  <mat-error *ngIf="complexForm.controls['name'].hasError('required')">Campo requerido</mat-error>
                </mat-form-field>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Descripción</mat-label>
                  <textarea matInput placeholder="Descripción" name="mDescription" rows="4" cols="80"
                    [formControl]="complexForm.controls['description']"
                    [(ngModel)]="object.main.description"></textarea>
                  <mat-error *ngIf="complexForm.controls['description'].hasError('required')">Campo requerido
                  </mat-error>
                </mat-form-field><br><br>

                <p class="titlebox">Datos en inglés</p>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Nombre del Evento en Inglés</mat-label>
                  <input matInput placeholder="Nombre del Evento en Inglés" name="nameEn"
                    [(ngModel)]="object.main.nameEn">
                </mat-form-field><br><br>

                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Descripción en Inglés</mat-label>
                  <textarea matInput placeholder="Descripción en Inglés" name="descriptionEn" rows="4" cols="80"
                    [(ngModel)]="object.main.descriptionEn"></textarea>
                </mat-form-field><br><br><br>
                                              
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="borderbox">
                <p class="titlebox">Precios (MXN)</p>
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Precio del evento</mat-label>
                  <input matInput placeholder="Precio del evento" name="priceEvent" type="number"
                    [formControl]="complexForm.controls['priceEvent']" [(ngModel)]="object.main.amount">
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="complexForm.controls['priceEvent'].hasError('required')">Campo requerido</mat-error>
                  <mat-hint align="start">Ingrese el precio en MXN</mat-hint>
                </mat-form-field>

                <p class="titlebox">Precios (USD)</p>
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>&nbsp;Precio del evento</mat-label>
                  <input matInput placeholder="Precio del evento" name="priceEventEn" type="number" [(ngModel)]="object.main.amountEn">
                  <span matPrefix>$&nbsp;</span>
                  <mat-hint align="start">Ingrese el precio en USD</mat-hint>
                </mat-form-field>
            
              </div>

              <div class="borderboxx">
                <p class="titlebox">Datos de contacto</p>
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Tel.</mat-label>
                  <input type="text" maxlength="10" onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                  matInput placeholder="Tel" name="phone" [formControl]="complexForm.controls['phone']"
                    [(ngModel)]="object.main.contact">
                  <mat-error *ngIf="complexForm.controls['phone'].hasError('required')">Campo requerido</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Email.</mat-label>
                  <input type="email" matInput placeholder="Email" name="email" [formControl]="complexForm.controls['email']"
                    [(ngModel)]="object.main.contactEmail">
                  <mat-error *ngIf="complexForm.controls['email'].hasError('required')">Campo requerido</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Celular.</mat-label>
                  <input type="text" maxlength="10" onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;" matInput placeholder="Tel"
                  name="phone_contact"
                    [formControl]="complexForm.controls['contac_phone']" [(ngModel)]="object.main.contacNumber">
                  <mat-error *ngIf="complexForm.controls['contac_phone'].hasError('required')">
                    Campo <strong>requerido</strong>.
                  </mat-error>
                </mat-form-field>

              </div>
            </div>

          </div><br>
          <div class="row justify-content-end mt-4">
            <div class="col-4">
              <button type="button" class="btn btn-danger btn-block" (click)="send();">Guardar</button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>