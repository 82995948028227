<mat-drawer-container class="drawerConteiner" >
  <mat-drawer #drawer mode="side" class="mainDrawer">
    <app-admin-menu></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawerContent">
    <app-tool-bar (menuToogle)="toogleMenu($event)"></app-tool-bar>
    <div class="row justify-content-center">
      <div class="col-10 text-right">
        <div class="row mt-2 mb-2 justify-content-end">
          <div class="col-12 text-left">
            <h3 class="text-uppercase pt-2 pb-2">Listado de lugares visitados</h3>
          </div>
          <!-- <div class="col-3">
            <button type="button" class="btn btn-danger btn-block" routerLink="/admin/places-visited/update">Agregar lugares visitados</button>
          </div> -->
        </div>
      </div>
      <div class="col-10">
        <mat-card class="text-center">
          <mat-card-content>
            <div class="row">
              <div class="col-12">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th style="width:30%;">LUGAR</th>
                      <th style="width:50%;">VISITAS</th>
                      <th style="width:20%;">COMENTARIOS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <th scope="row">
                        Matatlan
                      </th>
                      <th>
                        <div class="progress">
                          <div class="progress-bar bg-info" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                        </div>
                      </th>
                      <th>
                        <div class="row justify-content-center">
                          <div class="col-6">
                            <button mat-icon-button color="primary" matTooltip="10" (click)="commentaryUpdate();">
                              <mat-icon>assignment</mat-icon>
                            </button>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr >
                      <th scope="row">
                        Monte Albán
                      </th>
                      <th>
                        <div class="progress">
                          <div class="progress-bar bg-info" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80%</div>
                        </div>
                      </th>
                      <th>
                        <div class="row justify-content-center">
                          <div class="col-6">
                            <button mat-icon-button color="primary" matTooltip="25" (click)="commentaryUpdate();">
                              <mat-icon>assignment</mat-icon>
                            </button>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr >
                      <th scope="row">
                        Hierve el agua
                      </th>
                      <th>
                        <div class="progress">
                          <div class="progress-bar bg-info" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">70%</div>
                        </div>
                      </th>
                      <th>
                        <div class="row justify-content-center">
                          <div class="col-6">
                            <button mat-icon-button color="primary" matTooltip="1" (click)="commentaryUpdate();">
                              <mat-icon>assignment</mat-icon>
                            </button>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr >
                      <th scope="row">
                        Centro histórico
                      </th>
                      <th>
                        <div class="progress">
                          <div class="progress-bar bg-info" role="progressbar" style="width: 65%;" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">65%</div>
                        </div>
                      </th>
                      <th>
                        <div class="row justify-content-center">
                          <div class="col-6">
                            <button mat-icon-button color="primary" matTooltip="2" (click)="commentaryUpdate();">
                              <mat-icon>assignment</mat-icon>
                            </button>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
